import {
  Switch,
  Input,
  Space,
  TableProps,
  InputNumber,
  message,
  Select,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AntTable from "../components/CommonTable";
import API from "../api";
import CommonButton from "../components/CommonButton";
import { AxiosResponse } from "axios";
import { storage } from "../utils/storage";
import style from "../style/oneColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import SearchFeature from "./SearchFeture";

function CreateEditAccessory({
  mode,
  setMode,
  data,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: Data;
}) {
  const [formData, setFormData] = useState(data);
  const [accessoriesData, setAccessoriesData] = useState([] as any);
  const getAccessories = async () => {
    const getData = await API.admin.GetAccessories();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setAccessoriesData(getData.data?.data);
  };

  const onSave = async () => {
    const formDataCopy = { ...formData };
    if (formDataCopy.accessoryType && formDataCopy.accessoryType._id) {
      formDataCopy.accessoryType = formDataCopy.accessoryType
        ._id as unknown as any;
    }
    if (mode === "create") {
      delete formDataCopy._id;
    }
    if (mode === "edit") {
    }
    const createAccessory =
      mode === "create"
        ? await API.partner.CreateAccessory(formDataCopy)
        : await API.partner.EditAccessory(formData, data._id || "");
    if (
      API.validators.checkSuccessCode(
        createAccessory as AxiosResponse<any, any>
      )
    ) {
      message.success("Details Sumitted successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  useEffect(() => {
    getAccessories();
  }, [mode]);
  return (
    <div className={style["container"]}>
      <h1>Add Accessory Details</h1>
      <div className={style["inp-container"]}>
        <label htmlFor="chargingTime">
          Name<span>*</span>
        </label>
        <Input
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          name="name"
        />
      </div>
      <div className={style["inp-container-full"]}>
        <label htmlFor="accessoryType">
          Accessory<span>*</span>
        </label>
        <Select
          value={formData.accessoryType?._id}
          onChange={(value) => {
            const selectedAccessory = accessoriesData.find(
              (acc: any) => acc._id === value
            );
            setFormData({
              ...formData,
              accessoryType: selectedAccessory || {
                name: "",
                imgUrl: "",
                _id: "",
              },
            });
          }}
        >
          {accessoriesData.map((accessory: any) => (
            <Select.Option key={accessory._id} value={accessory._id}>
              {accessory.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={style["inp-container-full"]}>
        <label htmlFor="rentCalculations">
          Calculation Type<span>*</span>
        </label>
        <Select
          defaultValue="Trip"
          onChange={(value) => {
            setFormData({ ...formData, rentCalculations: value });
          }}
          options={[
            { value: "DAY", label: "Day" },
            { value: "TRIP", label: "Trip" },
          ]}
        />
      </div>
      <div className={style["inp-container-full"]}>
        <label htmlFor="price">
          Price<span>*</span>
        </label>
        <InputNumber
          value={formData.price}
          onChange={(value) => {
            setFormData({ ...formData, price: Number(value) });
          }}
          name="price"
        />
      </div>
      <div
        className={style["inp-container"]}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div style={{ marginRight: "16px" }}>disable</div>
        <Switch
          value={formData.disabled}
          onChange={(checked) =>
            setFormData({ ...formData, disabled: checked })
          }
        />
      </div>

      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => onSave()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
}

interface Data {
  _id?: string;
  accessoryType: { name: string; imgUrl: string; _id: string };
  name: string;
  rentCalculations: string;
  price: number;
  disabled: boolean;
  station: string;
}

const PartnerAccessories = () => {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState([] as Data[]);
  const [selectedData, setSelectedData] = useState({} as Data);
  const [originalData, setOriginalData] = useState<any[]>([]);

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Image",
      dataIndex: ["accessoryType", "imgUrl"], // Use array for nested data path
      key: "accessoryType.imgUrl",
      render: (text: any) => (
        <img style={{ width: "40px", height: "40px" }} src={text} alt="img" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text: any) => <p>{text}</p>,
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
        </Space>
      ),
    },
  ];
  const onEdit = (record: Data) => {
    setSelectedData(record);
    setMode("edit");
  };
  const onCreate = () => {
    setSelectedData({
      _id: "",
      accessoryType: { name: "", imgUrl: "", _id: "" },
      name: "",
      price: 0,
      disabled: false,
      rentCalculations: "",
      station: storage.getSelectedStation().id,
    });
    setMode("create");
  };

  const getAccessories = async () => {
    const getData = await API.partner.GetAccessories();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setData(getData.data?.data);
    setOriginalData(getData.data?.data);
  };
  useEffect(() => {
    getAccessories();
  }, [mode]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="name"
              placeholder="Search By Accessories Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Add Accessory
            </CommonButton>
          </div>

          <AntTable columns={columns} data={data} />
        </div>
      )}
      {mode === "create" && (
        <CreateEditAccessory
          mode="create"
          setMode={setMode}
          data={selectedData}
        />
      )}
      {mode === "edit" && (
        <CreateEditAccessory
          mode="edit"
          setMode={setMode}
          data={selectedData}
        />
      )}
    </div>
  );
};

export default PartnerAccessories;
