import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DatePicker, Input, message, Select } from "antd";
import API from "../api";
import dayjs, { Dayjs } from "dayjs";
import { AxiosResponse } from "axios";
import { storage } from "../utils/storage";
import CommonButton from "../components/CommonButton";
import style from "../style/oneColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import { ValidateMobileNumber } from "../utils/ValidationReg";
import { PaymentTypeEnum } from "../utils/const/bookingConst";
export interface BookingAccessory {
  qty: number;
  id: string;
}

export interface DeliveryInfo {
  address: string;
  deliveryCharge: any;
}

export interface DataForApiCall {
  userId: string;
  stationId: any;
  bikeId: any;
  accessories: BookingAccessory[];
  to: any;
  from: any;
  isPartial: boolean;
  deliveryInfo: DeliveryInfo | null;
}

interface BookingValues {
  name: string;
  email: string;
  phone: string;
  city: string;
  bikeId: string;
  from: string;
  to: string;
  stationId: string;
  paymentOption: PaymentTypeEnum;
  accessories: { qty: number; id: string }[];
  homeDelivery: boolean;
  homeDeliveryData: string;
  homeDeliveryAddress: string;
}

const CreateOrder = ({
  setMode,
  bookingValues,
}: {
  // mode: "edit" | "create" | "order";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home" | "order">>;
  bookingValues: BookingValues;
}) => {
  const [formData, setFormData] = useState({
    mobile: "",
    email: "",
    firstName: "",
    _id: "",
  });

  const handleFormFields = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const checkUserDetails = async () => {
    const checkUserDetailsResponse = await API.admin.CheckUserDetail(
      formData.mobile
    );
    if (!API.validators.checkSuccessCode(checkUserDetailsResponse)) {
      console.error("Technical error");
    }
    const resData = checkUserDetailsResponse.data.data;
    if (resData?._id && resData?.email && resData?.name) {
      setFormData({
        ...formData,
        email: resData?.email,
        firstName: resData?.name,
        _id: resData?._id,
      });
    } else if (resData?._id) {
      setFormData({
        ...formData,
        email: "",
        firstName: "",
        _id: resData?._id,
      });
    } else {
      setFormData({
        ...formData,
        email: "",
        firstName: "",
        _id: "",
      });
    }
  };

  const callCreateOrderApi = async ({ userId }: { userId: string }) => {
    console.log(userId, "userId");
    const isPartial =
      bookingValues.paymentOption === PaymentTypeEnum.PartialPayment;
    const dataForApiCall: DataForApiCall = {
      userId: userId,
      stationId: bookingValues.stationId,
      bikeId: bookingValues.bikeId,
      accessories: bookingValues.accessories,
      to: bookingValues.to,
      from: bookingValues.from,
      isPartial: isPartial,
      deliveryInfo: bookingValues.homeDeliveryAddress
        ? {
            address: bookingValues.homeDeliveryAddress,
            deliveryCharge: bookingValues.homeDeliveryData,
          }
        : null,
    };

    const createOrderApi = await API.admin.CreateOrder(dataForApiCall);
    if (!API.validators.checkSuccessCode(createOrderApi)) {
      console.error("Technical error");
    }
  };
  const saveUserDataOrder = async () => {
    const formDataSend = {
      email: formData.email,
      firstName: formData.firstName,
      mobile: formData.mobile,
      id: formData._id && formData._id,
    };
    const updateVal =
      formData.mobile && formData.email && formData.firstName && formData._id;
    const saveUserData = updateVal
      ? await API.admin.UpdateUserData(formDataSend)
      : await API.admin.SaveUserData(formDataSend);
    if (!API.validators.checkSuccessCode(saveUserData)) {
      console.error("Technical error");
    }
    const resData = saveUserData.data.data;
    if (resData?.user?._id) {
      console.log(resData?.user?._id, "resData?.user?._id");
      callCreateOrderApi({ userId: resData?.user?._id });
    }
    if (resData?._id) {
      console.log(resData?.user?._id, "resData?.user?._id");
      callCreateOrderApi({ userId: resData?._id });
    }
  };

  const disabledSaveBtn = () => {
    return !formData.mobile || !formData.email || !formData.firstName;
  };
  useEffect(() => {
    if (ValidateMobileNumber(formData.mobile)) {
      checkUserDetails();
    }
  }, [formData.mobile]);
  return (
    <div className={style["container"]}>
      <h1>User Details</h1>
      <div className={style["inp-container-full"]}>
        <label htmlFor="accessoryType">
          Phone No<span>*</span>
        </label>
        <Input
          value={formData.mobile}
          placeholder="Enter Phone"
          onChange={handleFormFields}
          name="mobile"
          maxLength={10}
        />
      </div>
      <div className={style["inp-container-full"]}>
        <label htmlFor="email">
          Email<span>*</span>
        </label>
        <Input
          value={formData.email}
          placeholder="Enter Email"
          onChange={handleFormFields}
          name="email"
        />
      </div>
      <div className={style["inp-container-full"]}>
        <label htmlFor="firstName">
          First Name<span>*</span>
        </label>
        <Input
          value={formData.firstName}
          placeholder="Enter First Name"
          onChange={handleFormFields}
          name="firstName"
        />
      </div>
      <div
        className={btnStyle["wrap-submit-cancel"]}
        style={{ width: "300px" }}
      >
        <CommonButton
          color="green"
          disabledValue={disabledSaveBtn()}
          onClick={saveUserDataOrder}
        >
          Save & Create Order
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
};
export default CreateOrder;
