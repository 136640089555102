import { Dispatch, SetStateAction, useEffect, useState } from "react";
import API from "../api";
import CommonButton from "../components/CommonButton";
import { AxiosResponse } from "axios";
import CommonTable from "../components/CommonTable";
import style from "../style/twoColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import { Input, Space, Switch, TableProps, message } from "antd";
import { CreatePartnerInterface, MODE } from "./partnersConst";
import PartnerHubs from "../components/partnerHubs";
import SearchFeature from "../partner/SearchFeture";

function CreateEditParnter({
  mode,
  setMode,
  data,
}: {
  mode: MODE.EDIT_PARTNER | MODE.CREATE_PARTNER;
  setMode: Dispatch<SetStateAction<MODE>>;
  data: CreatePartnerInterface;
}) {
  const [apiData, setApiData] = useState(data);

  const savePartner = async () => {
    let partner;

    if (mode === MODE.CREATE_PARTNER)
      partner = await API.admin.CreatePartner(apiData);
    if (mode === MODE.EDIT_PARTNER)
      partner = await API.admin.EditPartner(apiData, apiData._id);

    if (!API.validators.checkSuccessCode(partner as AxiosResponse<any, any>))
      return message.error("unable to create/Modify partner try again");

    message.success("Partner creation successful");

    setMode(MODE.HOME);
  };

  return (
    <div className={style["container"]}>
      <h1>Add Partner Details</h1>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="contactPerson">
            Partner Person<span>*</span>
          </label>
          <Input
            value={apiData.contactPerson}
            onChange={(e) => {
              setApiData({ ...apiData, contactPerson: e.target.value });
            }}
            name="contactPerson"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="companyName">
            Company Name<span>*</span>
          </label>
          <Input
            value={apiData.name}
            onChange={(e) => {
              setApiData({ ...apiData, name: e.target.value });
            }}
            name="companyName"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="bankAccountNumber">
            Bank Account Number<span>*</span>
          </label>

          <Input
            value={apiData.accountNumber}
            onChange={(e) => {
              setApiData({ ...apiData, accountNumber: e.target.value });
            }}
            name="bankAccountNumber"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="IFSCCode">
            IFSC Code<span>*</span>
          </label>
          <Input
            value={apiData.ifsc}
            onChange={(e) => {
              setApiData({ ...apiData, ifsc: e.target.value });
            }}
            name="IFSCCode"
          />
        </div>
      </div>

      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="branchName">
            Branch Name<span>*</span>
          </label>
          <Input
            value={apiData.bank}
            onChange={(e) => {
              setApiData({ ...apiData, bank: e.target.value });
            }}
            name="branchName"
          />
        </div>

        <div className={style["inp-container"]}>
          <label htmlFor="email">
            Email<span>*</span>
          </label>
          <Input
            value={apiData.email}
            onChange={(e) => {
              setApiData({ ...apiData, email: e.target.value });
            }}
            name="email"
          />
        </div>
      </div>

      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="mobileNo">
            Mobile no<span>*</span>
          </label>
          <Input
            value={apiData.phone}
            onChange={(e) => {
              setApiData({ ...apiData, phone: e.target.value });
            }}
            name="mobileNo"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="address">
            Address<span>*</span>
          </label>
          <Input
            value={apiData.address}
            onChange={(e) => {
              setApiData({ ...apiData, address: e.target.value });
            }}
            name="address"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="city">
            City<span>*</span>
          </label>
          <Input
            value={apiData.city}
            onChange={(e) => {
              setApiData({ ...apiData, city: e.target.value });
            }}
            name="city"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="state">
            State<span>*</span>
          </label>
          <Input
            value={apiData.state}
            onChange={(e) => {
              setApiData({ ...apiData, state: e.target.value });
            }}
            name="state"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="pincode">
            User Name<span>*</span>
          </label>
          <Input
            value={apiData.username}
            onChange={(e) => {
              setApiData({ ...apiData, username: e.target.value });
            }}
            name="username"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="pincode">
            Password<span>*</span>
          </label>
          <Input
            value={apiData.password}
            onChange={(e) => {
              setApiData({ ...apiData, password: e.target.value });
            }}
            name="password"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="pincode">
            Pincode<span>*</span>
          </label>
          <Input
            value={apiData.pincode}
            onChange={(e) => {
              setApiData({ ...apiData, pincode: Number(e.target.value) });
            }}
            name="pincode"
          />
        </div>
        <div
          className={style["inp-container"]}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "16px" }}>disable</div>
          <Switch
            value={apiData.disabled}
            onChange={(checked) =>
              setApiData({ ...apiData, disabled: checked })
            }
          />
        </div>
      </div>
      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => savePartner()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode(MODE.HOME)}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
}

function Partners() {
  const [mode, setMode] = useState(MODE.HOME as MODE);
  const [partnerData, setPartnerData] = useState(
    [] as CreatePartnerInterface[]
  );
  const [selectedPartnerData, setSelectedPartnerData] = useState(
    {} as CreatePartnerInterface
  );
  const [originalData, setOriginalData] = useState<any[]>([]);

  const onEdit = (record: CreatePartnerInterface) => {
    setSelectedPartnerData(record);
    setMode(MODE.EDIT_PARTNER);
  };

  const onCreate = () => {
    setMode(MODE.CREATE_PARTNER);
  };

  const onDelete = async (record: CreatePartnerInterface) => {
    const partner = await API.admin.DeletePartner(record._id);

    if (!API.validators.checkSuccessCode(partner)) {
      return message.error("unable to delete partner");
    }

    message.success("partner successfully deleted");
    getList();
  };

  const getList = async () => {
    const partners = await API.admin.GetPartners();
    if (!API.validators.checkSuccessCode(partners)) {
      return message.error("unable to fetch the partners list");
    }
    setPartnerData(partners.data?.data);
    setOriginalData(partners.data?.data);
  };

  useEffect(() => {
    getList();
  }, [mode]);

  const columns: TableProps<CreatePartnerInterface>["columns"] = [
    {
      title: "Partner Name",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (text: any) => <a>{text}</a>,
    },

    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setPartnerData}
              keyData="contactPerson"
              placeholder="Search By PArtner Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Create Partner
            </CommonButton>
          </div>

          <CommonTable
            columns={columns}
            data={partnerData.map((item) => {
              return { ...item, key: item._id };
            })}
            expandable={{
              expandedRowRender: (record: CreatePartnerInterface) => (
                <PartnerHubs id={record._id} />
              ),
            }}
          />
        </div>
      )}
      {mode === MODE.CREATE_PARTNER && (
        <CreateEditParnter
          mode={MODE.CREATE_PARTNER}
          setMode={setMode}
          data={selectedPartnerData}
        />
      )}
      {mode === MODE.EDIT_PARTNER && (
        <CreateEditParnter
          mode={MODE.EDIT_PARTNER}
          setMode={setMode}
          data={selectedPartnerData}
        />
      )}
    </div>
  );
}

export default Partners;
