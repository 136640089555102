// src/components/CustomButton.tsx
import React, { FC, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./commonBtn.module.scss";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: "secondary" | "primary" | "green";
  disabledValue?: boolean;
}

const CustomButton: FC<CustomButtonProps> = ({
  color,
  children,
  disabledValue,
  ...props
}) => {
  const buttonClass = classNames({
    [styles["secondary"]]: color === "secondary",
    [styles["primary"]]: color === "primary",
    [styles["green"]]: color === "green",
  });

  return (
    <button className={buttonClass} {...props} disabled={disabledValue}>
      {children}
    </button>
  );
};

export default CustomButton;
