import { Space, TableProps, message } from "antd";
import AntTable from "../components/CommonTable";
import API from "../api";
import dayjs from "dayjs";
export interface DataConst {
  from: string;
  to: string;
  stationId: string;
  bikeId: {
    bikeVariant: { imgUrl: string };
    name: string;
    _id: string;
  };
  _id?: string;
}
const TableComponent = ({
  data,
  setSelectedData,
  setMode,
  setRefresh,
  refresh,
}: {
  data: DataConst[];
  setSelectedData: (record: DataConst) => void;
  setMode: React.Dispatch<React.SetStateAction<"edit" | "create" | "home">>;
  setRefresh: any;
  refresh: boolean;
}) => {
  const onEdit = (record: DataConst) => {
    setSelectedData(record);
    setMode("edit");
  };

  const onDelete = async (record: DataConst) => {
    const Bike = await API.partner.DeleteBlockBike(record._id as string);
    if (!API.validators.checkSuccessCode(Bike))
      message.error("Technical error");
    message.success("Deleted Successfully");
    setRefresh(!refresh);
  };

  const columns: TableProps<DataConst>["columns"] = [
    {
      title: "Image",
      dataIndex: ["bikeId", "bikeVariant", "imgUrl"],
      key: "imgUrl",
      render: (imgUrl: string) => (
        <img style={{ width: "40px", height: "40px" }} src={imgUrl} alt="img" />
      ),
    },
    {
      title: "Name",
      dataIndex: ["bikeId", "bikeVariant", "name"],
      key: "name",
      render: (name: string) => <p>{name}</p>,
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text: any) => (
        <p>{dayjs(text).format("MMM DD, YYYY hh:mm A")}</p>
      ),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text: any) => (
        <p>{dayjs(text).format("MMM DD, YYYY hh:mm A")}</p>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];
  return <AntTable columns={columns} data={data} />;
};

export default TableComponent;
