import React, { useState } from "react";
import { ReactComponent as MotorcycleIcon } from "../assests/nav/motorcycle.svg";
import { ReactComponent as CityIcon } from "../assests/nav/city.svg";
import { ReactComponent as AccessoriesIcon } from "../assests/nav/accessories.svg";
import { ReactComponent as PartnersIcon } from "../assests/nav/partners.svg";
import { ReactComponent as HomeIcon } from "../assests/nav/home.svg";
import { ReactComponent as BookingIcon } from "../assests/bookingIcon.svg";
import { ReactComponent as Schedule } from "../assests/nav/schedule.svg";
import { Layout, Menu, theme } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import style from "../style/layouts.module.scss";
import TopHeader from "../components/TopHeader";
enum ROUTE {
  HOME = "home",
  CITY = "city",
  PARTNERS = "partners",
  BIKES = "bikes",
  ACCESSORIES = "accessories",
  BOOKING = "booking",
  SCHEDULE = "schedule",
}
const DASHBOARD_NAV_BAR = [
  {
    label: "Home",
    key: ROUTE.HOME,
    icon: <HomeIcon />,
  },
  {
    label: "Partners",
    key: ROUTE.PARTNERS,
    icon: <PartnersIcon />,
  },
  {
    label: "Bikes",
    key: ROUTE.BIKES,
    icon: <MotorcycleIcon style={{ width: "30px" }} />,
  },
  {
    label: "Accessories",
    key: ROUTE.ACCESSORIES,
    icon: <AccessoriesIcon />,
  },
  {
    label: "City",
    key: ROUTE.CITY,
    icon: <CityIcon />,
  },
  {
    label: "Booking",
    key: ROUTE.BOOKING,
    icon: <BookingIcon />,
  },
  {
    label: "Schedule",
    key: ROUTE.SCHEDULE,
    icon: <BookingIcon />,
  },
];

const { Header, Sider, Content } = Layout;

const getPathFromKey = (key: String): string => {
  return DASHBOARD_NAV_BAR.filter((entry) => entry.key === key)?.[0]?.key;
};

const AdminLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  return (
    <Layout className={style["main-container"]}>
      <Sider
        className={collapsed ? style["collapsed"] : style["not-collapsed"]}
      >
        <Menu
          theme="dark"
          mode="inline"
          items={[...DASHBOARD_NAV_BAR]}
          onSelect={({ key }) => {
            navigate(getPathFromKey(key));
          }}
        />
      </Sider>

      <Layout className={style["left-content"]}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className={style["wrap-header"]}>
            <TopHeader setCollapsed={setCollapsed} collapsed={collapsed} />
          </div>
        </Header>
        <Content
          style={{
            minHeight: 280,
            background: "transparent",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
