import { Button, Input, Space, TableProps, Upload, message } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AntTable from "../components/CommonTable";
import { UploadOutlined } from "@ant-design/icons";
import API from "../api";
import CommonButton from "../components/CommonButton";
import { AxiosResponse } from "axios";
import style from "../style/oneColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import SearchFeature from "../partner/SearchFeture";

function CreateEditAccessory({
  mode,
  setMode,
  data,
  id,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: Data;
  id?: string;
}) {
  const [formDataD, setFormDataD] = useState(data);
  const onSave = async () => {
    if (!formDataD.name) return message.error("Name is required");
    if (mode === "create" && !formDataD.img)
      return message.error("Image is required");

    const formData = new FormData();

    formData.append("name", formDataD.name);
    formDataD.img && formData.append("image", formDataD.img);
    // formData.append('disabled', formDataD.disabled || '')

    let createAccessory;

    if (mode === "create")
      createAccessory = await API.admin.CreateAccessory(formData);
    if (mode === "edit")
      createAccessory = await API.admin.EditAccessory(formData, formDataD._id);

    if (
      API.validators.checkSuccessCode(
        createAccessory as AxiosResponse<any, any>
      )
    ) {
      message.success("Details uploaded successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  return (
    <div className={style["container"]}>
      <h1>Add Accessory Details</h1>
      <label htmlFor="name">Name:</label>
      <Input
        value={formDataD.name}
        onChange={(e) => {
          setFormDataD({ ...formDataD, name: e.target.value });
        }}
        name="name"
      />
      <label htmlFor="uploade">Upload</label>
      <Upload
        beforeUpload={(file) => {
          setFormDataD({ ...formDataD, img: file });

          return false;
        }}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Upload image</Button>
      </Upload>
      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => onSave()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
}

interface Data {
  _id: string;
  name: string;
  imgUrl: string;
  img?: any;
}

function Accessories() {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState([] as Data[]);
  const [selectedData, setSelectedData] = useState({} as Data);
  const [originalData, setOriginalData] = useState<any[]>([]);

  const onEdit = (record: Data) => {
    setSelectedData(record);
    setMode("edit");
  };

  const onCreate = () => {
    setSelectedData({ _id: "", name: "", imgUrl: "", img: "" });
    setMode("create");
  };

  const onDelete = async (record: Data) => {
    const accessory = await API.admin.DeleteAccessory(record._id);

    if (!API.validators.checkSuccessCode(accessory))
      message.error("Technical error");

    message.success("Deleted Successfully");
    getAccessories();
  };

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Image",
      dataIndex: "imgUrl",
      key: "imgUrl",
      render: (text: any) => (
        <img style={{ width: "40px", height: "40px" }} src={text} alt="img" />
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  const getAccessories = async () => {
    const getData = await API.admin.GetAccessories();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setData(getData.data?.data);
    setOriginalData(getData.data?.data);
  };

  useEffect(() => {
    getAccessories();
  }, [mode]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="name"
              placeholder="Search By Accessories Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Add Accessory
            </CommonButton>
          </div>

          <AntTable columns={columns} data={data} />
        </div>
      )}
      {mode === "create" && (
        <CreateEditAccessory
          mode="create"
          setMode={setMode}
          data={selectedData}
        />
      )}
      {mode === "edit" && (
        <CreateEditAccessory
          mode="edit"
          setMode={setMode}
          data={selectedData}
        />
      )}
    </div>
  );
}

export default Accessories;
