import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Input, Space, TableProps, Upload, message } from "antd";
import { AxiosResponse } from "axios";
import CommonTable from "../components/CommonTable";
import { UploadOutlined } from "@ant-design/icons";
import API from "../api";
import style from "../style/oneColForm.module.scss";
import tableCol from "../style/tableCol.module.scss";
import btnStyle from "../style/btn.module.scss";
import CommonButton from "../components/CommonButton";
import SearchFeature from "../partner/SearchFeture";
const { TextArea } = Input;
const CreateEditCity = ({
  mode,
  setMode,
  data,
  id,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: Data;
  id?: string;
}) => {
  const [formDataD, setFormDataD] = useState(data);
  const onSave = async () => {
    if (!formDataD.name) return message.error("Name is required");
    if (mode === "create" && !formDataD.img)
      return message.error("Image is required");

    const formData = new FormData();
    formData.append("name", formDataD.name);
    formDataD.img && formData.append("image", formDataD.img);
    formData.append("customHtml", formDataD.customHtml || "");
    formData.append("customMetaTags", formDataD.customMetaTags || "");
    let createCity;
    if (mode === "create") createCity = await API.admin.CreateCity(formData);
    if (mode === "edit")
      createCity = await API.admin.EditCity(formData, formDataD._id);

    if (
      API.validators.checkSuccessCode(createCity as AxiosResponse<any, any>)
    ) {
      console.log(createCity);
      message.success("Details uploaded successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  return (
    <div className={style["container"]}>
      <h1>Add City Details</h1>
      <label htmlFor="name">
        Name<span>*</span>
      </label>
      <Input
        value={formDataD.name}
        onChange={(e) => {
          setFormDataD({ ...formDataD, name: e.target.value });
        }}
        name="name"
      />
      <label htmlFor="customMetaTags">Custom Meta Tags</label>
      <Input
        value={formDataD.customMetaTags}
        onChange={(e) => {
          setFormDataD({ ...formDataD, customMetaTags: e.target.value });
        }}
        name="customMetaTags"
      />
      <label htmlFor="customHtml">Custom Html</label>
      <TextArea
        value={formDataD.customHtml}
        onChange={(e) => {
          setFormDataD({ ...formDataD, customHtml: e.target.value });
        }}
        name="customHtml"
      />

      <label htmlFor="uploade">
        Upload<span>*</span>
      </label>
      <Upload
        beforeUpload={(file) => {
          setFormDataD({ ...formDataD, img: file });

          return false;
        }}
        maxCount={1}
        name="uploade"
      >
        <Button icon={<UploadOutlined />}>Upload image</Button>
      </Upload>
      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => onSave()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
};

interface Data {
  _id: string;
  name: string;
  imgUrl: string;
  disabled: boolean;
  customHtml?: string;
  customMetaTags?: string;
  img?: any;
}

const City = () => {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState({} as Data);
  const onEdit = (record: Data) => {
    setSelectedData(record);
    setMode("edit");
  };

  const onCreate = () => {
    setSelectedData({
      _id: "",
      name: "",
      imgUrl: "",
      disabled: false,
      customHtml: "",
      customMetaTags: "",
      img: "",
    });
    setMode("create");
  };

  const onDelete = async (record: Data) => {
    const city = await API.admin.DeleteCity(record._id);

    if (!API.validators.checkSuccessCode(city))
      return message.error("Technical error");

    message.success("Deleted Successfully");
    getCities();
  };

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Image",
      dataIndex: "imgUrl",
      key: "imgUrl",
      render: (text: any) => (
        <img style={{ width: "40px", height: "40px" }} src={text} alt="img" />
      ),
      width: 100,
    },
    {
      title: "City Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
      width: 350,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
      width: 200,
    },
  ];

  const getCities = async () => {
    const getData = await API.admin.GetCities();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    const formattedTableData = [];
    const responseData = getData.data?.data;
    for (let i = 0; i < responseData?.length; i++) {
      formattedTableData.push({
        cityName: (
          <div className={tableCol["wrap-img-name"]}>
            <img
              style={{ width: "100px", height: "40px" }}
              src={responseData[i].imgUrl}
              alt="img"
            />
            <p className={tableCol["name"]}>{responseData[i].name}</p>
          </div>
        ),
        action: (
          <Space size="middle">
            <a onClick={() => onEdit(responseData[i].id)}>Edit</a>
            <a onClick={() => onDelete(responseData[i].id)}>Delete</a>
          </Space>
        ),
      });
    }
    setOriginalData(responseData);
    setData(responseData);
  };

  useEffect(() => {
    getCities();
  }, [mode]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="name"
              placeholder="Search By City Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Create City
            </CommonButton>
          </div>

          <CommonTable columns={columns} data={data} />
        </div>
      )}
      {mode === "create" && (
        <CreateEditCity mode="create" setMode={setMode} data={selectedData} />
      )}
      {mode === "edit" && (
        <CreateEditCity mode="edit" setMode={setMode} data={selectedData} />
      )}
    </div>
  );
};

export default City;
