import React from "react";

function PartnerDashboard() {
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "24px",
        opacity: ".5",
      }}
    >
      Commin Soon
    </div>
  );
}

export default PartnerDashboard;
