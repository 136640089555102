import CommonButton from "../components/CommonButton";
import { Drawer, Space } from "antd";
import dayjs from "dayjs";
import { Data, orderItems } from "../utils/const/bookingConst";
import style from "../style/tableCol.module.scss";
import Item from "antd/es/list/Item";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import { changeTextColor } from "../utils/changeTextColor";
const BookingDetailsDrawer = ({
  showDrawer,
  setShowDrawer,
  selectedData,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  selectedData: any;
}) => {
  console.log(selectedData, "selectedData");
  return (
    <Drawer
      title="Details"
      placement={"right"}
      width={500}
      onClose={() => setShowDrawer(false)}
      open={showDrawer}
      closable={false}
      extra={
        <Space>
          <CommonButton color="primary" onClick={() => setShowDrawer(false)}>
            Close
          </CommonButton>
        </Space>
      }
    >
      <div
        className={style["container"]}
        style={{ width: "450px", padding: "unset", boxShadow: "none" }}
      >
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Booking Id:</p>
          <p className={style["sub-title-value"]}>{selectedData._id}</p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Name:</p>
          <p className={style["sub-title-value"]}>
            {selectedData?.userId?.firstName || "--"}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Email:</p>
          <p className={style["sub-title-value"]}>
            {selectedData?.userId?.email || "--"}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Phone:</p>
          <p className={style["sub-title-value"]}>
            {selectedData?.userId?.mobile || "--"}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Pickup:</p>
          <p className={style["sub-title-value"]}>
            {dayjs(selectedData?.from).format("MMM DD, YYYY hh:mm A")}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Dropoff:</p>
          <p className={style["sub-title-value"]}>
            {" "}
            {dayjs(selectedData?.to).format("MMM DD, YYYY hh:mm A")}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Station Name:</p>
          <p className={style["sub-title-value"]}>
            {selectedData?.stationId?.name}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Source:</p>
          <p className={style["sub-title-value"]}>
            {selectedData?.orderId?.client}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div>
          <p className={style["drawer-title"]}>Order Items:</p>
          <div className={style["sub-title-value"]}>
            {selectedData?.orderId?.orderItems.map((item: any) => {
              return (
                <div key={item._id}>
                  <p className={style["drawer-title"]}>
                    {ConvertFirstLetter(item.type)}
                  </p>
                  <div className={style["wrap-img-name"]}>
                    <div className={style["wrap-img-name"]}>
                      <p className={style["sub-title"]}>Quantity:</p>
                      <p className={style["sub-title-value"]}>{item?.qty}</p>
                    </div>
                    <div className={style["wrap-img-name"]}>
                      <p className={style["sub-title"]}>Amount:</p>
                      <p className={style["sub-title-value"]}>
                        {item?.totalAmt}
                      </p>
                    </div>
                    <div className={style["wrap-img-name"]}>
                      <p className={style["sub-title"]}>Delivery Charges:</p>
                      <p className={style["sub-title-value"]}>
                        {item?.deliveryCharges}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Delivery Address:</p>
          <p className={style["value"]}>
            {selectedData?.orderId?.deliveryAddress || "--"}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Booking Status:</p>
          <p
            className={style["value"]}
            style={{ color: changeTextColor(selectedData?.type) }}
          >
            {ConvertFirstLetter(selectedData?.type)}
          </p>
        </div>
        <hr className={style["hr-line"]} />
        <div className={style["wrap-img-name"]}>
          <p className={style["drawer-title"]}>Ride Status:</p>
          <p
            className={style["value"]}
            style={{ color: changeTextColor(selectedData?.rideStatus) }}
          >
            {ConvertFirstLetter(selectedData?.rideStatus)}
          </p>
        </div>
      </div>
    </Drawer>
  );
};
export default BookingDetailsDrawer;
