import { useState } from "react";
import { TableProps } from "antd";
import AntTable from "../components/CommonTable";
import dayjs from "dayjs";
import tableStyle from "../style/tableCol.module.scss";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import { Data, BookingData } from "../utils/const/bookingConst";
import { ReactComponent as OpenLink } from "../assests/nav/open_link.svg";
import hamburger from "../assests/nav/hamburger.svg";
import { changeTextColor } from "../utils/changeTextColor";
const BookingTable = ({
  data,
  setShowDrawer,
  setSelectedData,
  isDropOff,
  isPickup,
}: {
  data: BookingData[];
  setShowDrawer: any;
  setSelectedData: any;
  isDropOff?: boolean;
  isPickup?: boolean;
}) => {
  const [showEditDeleteAndUdateMenu, setShowEditDeleteAndUdateMenu] = useState({
    show: false,
    id: "",
  });

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Booking Id",
      dataIndex: "_id",
      key: "_id",
      width: 200,
      render: (_, record) => (
        <div
          className={tableStyle["wrap-img-name"]}
          style={{ justifyContent: "flex-start" }}
        >
          <p className={tableStyle["name"]}>{record._id ? record._id : "--"}</p>
          <OpenLink
            onClick={() => {
              setShowDrawer(true);
              setSelectedData(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: ["userId", "mobile"],
      key: "mobile",
      width: 150,
      render: (mobile: string) => <p>{mobile ? mobile : "--"}</p>,
    },
    {
      title: "Booking Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (name: string) => (
        <p style={{ color: changeTextColor(name) }}>
          {ConvertFirstLetter(name)}
        </p>
      ),
    },
    {
      title: "Station Name",
      dataIndex: ["stationId", "name"],
      key: "name",
      width: 200,
      render: (name: string) => <p>{ConvertFirstLetter(name)}</p>,
    },
    {
      title: "Pickup",
      dataIndex: "from",
      key: "from",
      width: 200,
      render: (text: any) => (
        <p>{dayjs(text).format("MMM DD, YYYY hh:mm A")}</p>
      ),
    },
    {
      title: "Dropoff",
      dataIndex: "to",
      key: "to",
      width: 200,
      render: (text: any) => (
        <p>{dayjs(text).format("MMM DD, YYYY hh:mm A")}</p>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 70,
      render: (_, record) => (
        <>
          {showEditDeleteAndUdateMenu.show &&
            showEditDeleteAndUdateMenu.id === record._id && (
              <div className={tableStyle["edit-delete-update-wrap"]}>
                <div className={tableStyle["blue-text"]}>Cancel Booking</div>
                {/* <div className={tableStyle["blue-text"]}>Details</div> */}
              </div>
            )}
          <div
            onClick={() => {
              setShowEditDeleteAndUdateMenu({
                show: true,
                id: record._id,
              });
            }}
            className={tableStyle["edit-delete-update-img"]}
          >
            <img src={hamburger} alt="is edit" />
          </div>
        </>
      ),
    },
  ];
  if (isDropOff || isPickup) {
    columns.splice(3, 0, {
      title: "Ride Status",
      dataIndex: "rideStatus",
      key: "rideStatus",
      width: 150,
      render: (name: string) => (
        <p style={{ color: changeTextColor(name) }}>
          {ConvertFirstLetter(name)}
        </p>
      ),
    });
  }
  if (isDropOff) {
    columns.splice(5, 1);
  }
  if (isPickup) {
    columns.splice(6, 1);
  }
  return <AntTable columns={columns} data={data} />;
};

export default BookingTable;
