export function isValidUrl(str: string) {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(str);
}

export function isValidMobileNumber(str: string) {
  const regexp = /^\d{10}$/;
  return regexp.test(str);
}

export function isValidEmail(str: string) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(str);
}

export function isValidSecondaryPhone(str: string) {
  const regexp = /^[1-9]\d{0,14}$/;
  return regexp.test(str);
}
interface ValidationRules {
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
}

export const validate = (item: any, rules: ValidationRules): boolean => {
  if (rules.minLength && item.length < rules.minLength) return false;
  if (rules.maxLength && item.length > rules.maxLength) return false;
  if (rules.pattern && !rules.pattern.test(item)) return false;
  return true;
};

export const ValidateMobileNumber = (phoneNubmer: string) => {
  const re = /^[6-9][0-9]{9}$/;
  return re.test(phoneNubmer);
};
