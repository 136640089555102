export enum SUB_DOMAIN_TYPE {
  ADMIN = "admin",
  PARTNER = "partner",
}
enum STORAGE_VAR {
  AUTH = "authDetails",
  STATION = "station",
}

interface authDetails {
  token: string;
  userType: SUB_DOMAIN_TYPE;
  version: number;
  sessionId?: string;
  expiresAt?: string;
  userName: string;
  _id?: string;
}
interface stationDetails {
  id: string;
  name: string;
}
export const storage = {
  setAuthDetails: ({
    token,
    userType,
    expiresAt,
    version,
    sessionId,
    userName,
    _id,
  }: authDetails) => {
    const stringified = JSON.stringify({
      token,
      userType,
      version,
      expiresAt,
      sessionId,
      userName,
      _id,
    });
    localStorage.setItem(STORAGE_VAR.AUTH, stringified);
  },
  clearAuthDetails: () => {
    localStorage.removeItem(STORAGE_VAR.AUTH);
  },

  getAuthDetails: (
    defaultType: SUB_DOMAIN_TYPE = SUB_DOMAIN_TYPE.PARTNER
  ): authDetails => {
    const localValue = localStorage.getItem(STORAGE_VAR.AUTH);
    let authValues = {
      token: "",
      userType: defaultType,
      expiresAt: "",
      sessionId: "",
      version: 0,
      userName: "",
      _id: "",
    } as authDetails;
    if (localValue) {
      authValues = JSON.parse(localValue);
    }

    return {
      token: authValues?.token as string,
      userType: authValues?.userType,
      expiresAt: authValues?.expiresAt as string,
      version: authValues?.version as number,
      sessionId: authValues.sessionId as string,
      userName: authValues.userName as string,
      _id: authValues._id as string,
    };
  },

  checkPartnerDashboardAccess: function (): {
    hasAccess: Boolean;
    userType: SUB_DOMAIN_TYPE;
  } {
    const localValues = this.getAuthDetails(SUB_DOMAIN_TYPE.PARTNER);
    return {
      hasAccess:
        !!localValues.token && localValues.userType === SUB_DOMAIN_TYPE.PARTNER,
      userType: localValues.userType,
    };
  },
  checkAdminDashboardAccess: function (): {
    hasAccess: Boolean;
    userType: SUB_DOMAIN_TYPE;
  } {
    const localValues = this.getAuthDetails(SUB_DOMAIN_TYPE.ADMIN);
    return {
      hasAccess:
        !!localValues.token && localValues.userType === SUB_DOMAIN_TYPE.ADMIN,
      userType: localValues.userType,
    };
  },
  setSelectedStation: ({ id, name }: { id: string; name: string }) => {
    const stationDataStringify = JSON.stringify({
      id,
      name,
    });
    localStorage.setItem(STORAGE_VAR.STATION, stationDataStringify);
  },
  clearSelectedStation: () => {
    localStorage.removeItem(STORAGE_VAR.STATION);
  },
  // getSelectedStation: () => {
  //   return localStorage.getItem(STORAGE_VAR.STATION);
  // },
  getSelectedStation: (): stationDetails => {
    const localValue = localStorage.getItem(STORAGE_VAR.STATION);
    let stationValues = {
      id: "",
      name: "",
    } as stationDetails;
    if (localValue) {
      stationValues = JSON.parse(localValue);
    }

    return {
      name: stationValues.name as string,
      id: stationValues.id as string,
    };
  },
};
