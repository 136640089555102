import { Empty, Table } from "antd";
import style from "./table.module.scss";
import { useState } from "react";
const CommonTable = ({
  data,
  columns,
  expandable,
}: {
  data: any;
  columns: any;
  expandable?: any;
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([] as any);
  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
    }

    setExpandedRowKeys(keys);
  };
  return (
    <div className={style["container"]}>
      {data.length > 0 ? (
        <Table
          bordered
          columns={columns}
          dataSource={data}
          expandable={expandable}
          pagination={false}
          scroll={{ x: 300 }}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default CommonTable;
