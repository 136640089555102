import axios, { AxiosResponse } from "axios";
import { SUB_DOMAIN_TYPE, storage } from "../utils/storage";
export const API_URL = `${process.env.REACT_APP_API_URL}`;

enum REST_METHODS {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
}

axios.defaults.validateStatus = function () {
  return true;
};

axios.interceptors.response.use(function (response) {
  const userType = storage.getAuthDetails().userType;

  if (response.status === 401) {
    if (
      response.request.responseURL.includes("login-otp") ||
      response.request.responseURL.includes("login-password")
    )
      return response;
    if (userType === SUB_DOMAIN_TYPE.PARTNER)
      window.location.href = `/${SUB_DOMAIN_TYPE.PARTNER}`;
    else if (userType === SUB_DOMAIN_TYPE.ADMIN)
      window.location.href = `/${SUB_DOMAIN_TYPE.ADMIN}`;
    else window.location.reload();
  }

  return response;
});

const apiCaller = async (
  {
    method,
    params,
    url,
    data,
    headers,
    excludeAuthToken,
  }: {
    method: REST_METHODS;
    params?: Record<string, unknown>;
    url: string;
    data?: Record<string, unknown> | FormData;
    headers?: Record<string, unknown>;
    excludeAuthToken?: boolean;
  } = {
    method: REST_METHODS.GET,
    params: {},
    data: {},
    headers: {},
    url: "",
    excludeAuthToken: false,
  }
) => {
  const authDetails = storage.getAuthDetails();
  console.log(authDetails.token, "authDetails");
  const userToken = authDetails.token;

  // const userToken = "";
  // const userType = authDetails.userType;
  // const authExpiry = authDetails.expiresAt;
  // const tokenVersion = authDetails.version;

  if (userToken && !excludeAuthToken) {
    headers = { ...headers, Authorization: `Bearer ${userToken}` };
  }

  if (method === REST_METHODS.GET) {
    //@ts-ignore
    return axios.get(url, { params, headers });
  }
  //@ts-ignore
  return axios({
    method: method,
    url,
    data,
    headers,
    params,
  });
};

const API = {
  validators: {
    checkSuccessCode: (response: AxiosResponse): boolean => {
      return Math.floor(response.status / 100) === 2;
    },
    matchStatusCode: (response: AxiosResponse, statusCode: number): boolean => {
      return response.status === statusCode ? true : false;
    },
  },
  auth: {
    PartnerLogin: ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { login: email, password: password },
        url: `${API_URL}/auth/partner/login`,
      });
    },
    PartnerLogout: ({ sessionId }: { sessionId: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { sessionId: sessionId },
        url: `${API_URL}/auth/partner/logout`,
      });
    },
    AdminLogin: ({ email, password }: { email: string; password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { login: email, password: password },
        url: `${API_URL}/auth/admin/login`,
      });
    },
    AdminLogout: ({ sessionId }: { sessionId: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { sessionId: sessionId },
        url: `${API_URL}/auth/admin/logout`,
      });
    },
    AdminSignUp: ({ email, password }: { email: string; password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { email, password },
        url: `${API_URL}/auth/admin/signup`,
      });
    },
  },

  partner: {
    GetBikes: ({ stationId }: { stationId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/bike/bike-list/${stationId}`,
      });
    },
    GetBikesVariant: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/bike/variant/list`,
      });
    },
    DeleteBike: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/bike/${id}`,
      });
    },
    CreateBike: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/bike/`,
        data: data,
      }),
    EditBike: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/bike/${id}`,
        data: data,
      }),
    GetStations: ({ _id }: { _id?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/station/station-list/${_id}`,
      });
    },
    GetAccessories: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/accessory/partner/list`,
      });
    },
    CreateAccessory: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/accessory/partner`,
        data: data,
      }),
    EditAccessory: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/accessory/partner/${id}`,
        data: data,
      }),
    GetBlockBikeList: ({ stationId }: { stationId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/booking/maintenance/station/${stationId}`,
      });
    },
    AddBlockBike: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/booking/maintenance/create`,
        data: data,
      }),
    DeleteBlockBike: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/booking//maintenance/booking/${id}`,
      });
    },
  },
  admin: {
    GetCities: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/city/list`,
      });
    },
    GetStationList: ({
      city,
      pickupDate,
      dropoffDate,
    }: {
      city?: string[];
      pickupDate?: string;
      dropoffDate?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/station/all`,
        data: {
          city,
          pickupDate,
          dropoffDate,
        },
      }),
    GetBookingData: ({
      email,
      phone,
      name,
      bookingId,
      cityId,
      stationId,
      bookingStatus,
      rideStatus,
      pickupFrom,
      pickupTo,
      dropoffFrom,
      dropoffTo,
    }: {
      email?: string;
      phone?: string;
      name?: string;
      bookingId?: string;
      cityId?: string;
      stationId?: string;
      bookingStatus?: string;
      rideStatus?: string;
      pickupFrom?: any;
      pickupTo?: any;
      dropoffFrom?: any;
      dropoffTo?: any;
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/booking/bookings/all`,
        data: {
          email,
          phone,
          name,
          bookingId,
          cityId,
          stationId,
          bookingStatus,
          rideStatus,
          pickupFrom,
          pickupTo,
        },
      }),
    SelectedCityAvailableBikes: ({
      city,
      pickupDate,
      dropoffDate,
      station,
    }: {
      city: string[];
      pickupDate: string;
      dropoffDate: string;
      station: string[];
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/bike/available/all`,
        data: {
          city,
          pickupDate,
          dropoffDate,
          station,
        },
      }),
    GetCity: ({ clientId }: { clientId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/login-as-client`,
        params: {
          ...(clientId && { clientId }),
        },
      });
    },
    DeleteCity: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/city/${id}`,
      });
    },
    CreateCity: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/city/`,
        data: data,
      }),
    EditCity: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/city/${id}`,
        data: data,
      }),

    CreatePartner: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/partner/`,
        data: data,
      }),

    GetPartners: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/partner/list`,
      });
    },
    DeletePartner: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/partner/${id}`,
      });
    },
    EditPartner: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/partner/${id}`,
        data: data,
      }),

    CreateStation: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/station/`,
        data: data,
      }),

    // GetStations: () => {
    //   return apiCaller({
    //     method: REST_METHODS.GET,
    //     url: `${API_URL}/station/list`,
    //   });
    // },
    GetStations: ({ _id }: { _id?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/station/station-list/${_id}`,
      });
    },
    DeleteStation: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/station/${id}`,
      });
    },
    EditStation: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/station/${id}`,
        data: data,
      }),
    GetBikes: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/bike/variant/list`,
      });
    },
    DeleteBike: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/bike/variant/${id}`,
      });
    },
    CreateBike: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/bike/variant/`,
        data: data,
      }),
    EditBike: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/bike/variant/${id}`,
        data: data,
      }),
    GetAccessories: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/accessory/type/list`,
      });
    },
    DeleteAccessory: (id: string) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/accessory/type/${id}`,
      });
    },
    CreateAccessory: (data: any) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/accessory/type/`,
        data: data,
      }),
    EditAccessory: (data: any, id: string) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/accessory/type/${id}`,
        data: data,
      }),
    CheckoutPage: ({
      bikeId,
      from,
      to,
    }: {
      bikeId: any;
      from: Date;
      to: Date;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          bikeId: bikeId,
          from: from,
          to: to,
        },
        url: `${API_URL}/bike/checkout-availability`,
      });
    },
    CheckUserDetail: (mobile: string) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          mobile: mobile,
        },
        url: `${API_URL}/auth/user/by/mobile`,
      });
    },
    SaveUserData: ({
      mobile,
      email,
      firstName,
    }: {
      mobile: string;
      email: string;
      firstName: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          mobile: mobile,
          email: email,
          firstNmae: firstName,
        },
        url: `${API_URL}/auth/user/admin/signup`,
      });
    },
    CreateOrder: ({
      userId,
      stationId,
      bikeId,
      accessories,
      deliveryInfo,
      isPartial,
      from,
      to,
    }: {
      userId: string;
      stationId: any;
      bikeId: any;
      accessories?: any;
      deliveryInfo?: any;
      isPartial?: boolean;
      from: any;
      to: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          userId,
          stationId,
          bikeId,
          accessories,
          deliveryInfo,
          isPartial,
          from,
          to,
        },
        url: `${API_URL}/booking/create-order`,
      });
    },
    UpdateUserData: ({
      mobile,
      email,
      firstName,
      id,
    }: {
      mobile: string;
      email: string;
      firstName: string;
      id: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          mobile: mobile,
          email: email,
          name: firstName,
          id: id,
        },
        url: `${API_URL}/auth/user/admin/update`,
      });
    },
  },
};

export default API;
