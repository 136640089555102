export enum MODE {
  HOME = "home",
  EDIT_PARTNER = "edit-partner",
  CREATE_PARTNER = "create-partner",
  CREATE_STATION = "create-hub",
  EDIT_STATION = "edit-partner",
}

export interface CreatePartnerInterface {
  key: string;
  _id: string;
  name: string;
  contactPerson: string;
  gstin: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  pincode: number;
  bank: string;
  ifsc: string;
  accountNumber: string;
  disabled?: boolean;
  username: string;
  password: string;
}

export interface StationInterface {
  _id?: string;
  partner: string;
  name: string;
  disabled: boolean;
  address: string;
  city: string;
  pincode: number;
  share: number;
  landmark: string;
  map: string;
  directions: string;
  phone: string[];
  email: string;
  allowPartial: boolean;
  priceCalculation: string;
  tnc: string[];
  openingTime: string;
  closingTime: string;
  delivery: boolean;
  deliveryCharge: {
    upto10?: number;
    upto20?: number;
  };
  prepTime?: number;
  closingDays: number[];
}
export const defaultStationData = {
  _id: "",
  partner: "",
  name: "",
  disabled: false,
  address: "",
  city: "",
  pincode: 0,
  landmark: "",
  map: "",
  directions: "",
  phone: ["", ""],
  email: "",
  allowPartial: true,
  priceCalculation: "DAY",
  tnc: [""],
  openingTime: "",
  closingTime: "",
  delivery: true,
  deliveryCharge: {
    upto10: 0,
    upto20: 0,
  },
  prepTime: 30,
  share: 1,
  closingDays: [],
};
export const stationCol = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "First Phone No",
    dataIndex: "phone_1",
    key: "phone_1",
  },
  {
    title: "Second Phone No",
    dataIndex: "phone_2",
    key: "phone_2",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
export const timeFormate = [
  { value: "00" },
  { value: "01" },
  { value: "02" },
  { value: "03" },
  { value: "04" },
  { value: "05" },
  { value: "06" },
  { value: "07" },
  { value: "08" },
  { value: "09" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23" },
];
export const WeekDays = [
  { id: 0, label: "S" },
  { id: 1, label: "M" },
  { id: 2, label: "T" },
  { id: 3, label: "W" },
  { id: 4, label: "T" },
  { id: 5, label: "F" },
  { id: 6, label: "S" },
];
