export const changeTextColor = (status: string) => {
  if (status === "BOOKED" || status === "COMPLETED" || status === "BOOKING") {
    return "green";
  } else if (status === "CANCELLED") {
    return "red";
  } else if (status === "NOT_STARTED") {
    return "gray";
  } else if (status === "ONGOING") {
    return "orange";
  }

  return "gray";
};
