import { DatePicker, message, Select } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import API from "../api";
import dayjs, { Dayjs } from "dayjs";
import { AxiosResponse } from "axios";
import { storage } from "../utils/storage";
import CommonButton from "../components/CommonButton";
import style from "../style/oneColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import { DataConst } from "./blockConsts";
const { RangePicker } = DatePicker;
const PartnerBlocksEditAndCreateForm = ({
  mode,
  setMode,
  data,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: DataConst;
}) => {
  const [formData, setFormData] = useState(data);
  const [bikeList, setBikeList] = useState([] as any);
  // const [dateRange, setDateRange] = useState(null as [Dayjs, Dayjs] | null);
  const getBikesList = async () => {
    const stationId = storage.getSelectedStation().id;
    if (!stationId) return;
    const getData = await API.partner.GetBikes({ stationId: stationId });

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setBikeList(getData.data?.data);
  };

  const onSave = async () => {
    const formDataCopy = { ...formData };
    if (formDataCopy.bikeId && formDataCopy.bikeId._id) {
      formDataCopy.bikeId = formDataCopy.bikeId._id as unknown as any;
    }
    if (mode === "create") {
      delete formDataCopy._id;
    }
    const createAccessory =
      mode === "create"
        ? await API.partner.AddBlockBike(formDataCopy)
        : await API.partner.EditAccessory(formDataCopy, data._id || "");
    if (
      API.validators.checkSuccessCode(
        createAccessory as AxiosResponse<any, any>
      )
    ) {
      message.success("Details Sumitted successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;

      if (start && end && end.isBefore(start)) {
        message.error("End date cannot be earlier than start date.");
        return;
      }

      setFormData({
        ...formData,
        from: start?.toISOString() || "",
        to: end?.toISOString() || "",
      });
    } else {
      setFormData({
        ...formData,
        from: "",
        to: "",
      });
    }
  };
  const disabledPastDate = (current: Dayjs) => {
    return current && current.isBefore(dayjs().startOf("day")); // Disable dates before today
  };

  useEffect(() => {
    getBikesList();
  }, [mode]);
  return (
    <div className={style["container"]}>
      <h1>Block bike Details</h1>
      <div className={style["inp-container-full"]}>
        <label htmlFor="accessoryType">
          Bike Name<span>*</span>
        </label>
        <Select
          placeholder="Select Bike"
          value={formData.bikeId?._id || undefined}
          disabled={mode === "edit"}
          onChange={(value) => {
            const selectedBike = bikeList.find((acc: any) => acc._id === value);
            if (selectedBike) {
              setFormData({
                ...formData,
                bikeId: selectedBike,
              });
            }
          }}
        >
          {bikeList.map((bike: any) => (
            <Select.Option key={bike._id} value={bike._id}>
              {bike.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={style["inp-container-full"]}>
        <label htmlFor="station">
          Select Date Range<span>*</span>
        </label>
        <RangePicker
          showTime
          format="YYYY-MM-DD HH:mm" // Date and hours format
          getPopupContainer={(trigger: any) => trigger.parentNode}
          onChange={handleDateChange}
          disabledDate={disabledPastDate}
          size="small"
          value={
            formData.from && formData.to
              ? [dayjs(formData.from), dayjs(formData.to)] // Parsing ISO strings back to Dayjs objects
              : null
          }
        />
      </div>

      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => onSave()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
};
export default PartnerBlocksEditAndCreateForm;
