import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import API from "../api";
import btnStyle from "../style/btn.module.scss";
import style from "../style/twoColForm.module.scss";
import CommonButton from "../components/CommonButton";
import { message, Select, DatePicker } from "antd";
import RadioBtnList from "../components/booking/RadioBtnList";
import SwitchBtn from "../components/booking/SwitchBtn";
import RadioBtnListWithPrice from "../components/booking/RadioBtnListWithPrice";
import CheckBoxGroupWithQuantity from "../components/booking/CheckBoxGroupWithQuantity";
import TextArea from "antd/es/input/TextArea";
import {
  paymentType,
  HomeDeliveryEnum,
  PaymentTypeEnum,
  homeDeliveryOptions,
  PriceInfo,
  initialPricing,
  initialDelivery,
  initialBikeDetails,
} from "../utils/const/bookingConst";

import { formatNumber } from "../components/booking/formatNumber";
import { disabledPastDate } from "../utils/disabledPastDate";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface City {
  _id: string;
  name: string;
}

function CreateBooking({
  setMode,
  setBookingValues,
  bookingValues,
}: {
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home" | "order">>;
  setBookingValues: any;
  bookingValues: any;
}) {
  const [accessoriesTotalPrice, setAccessoriesTotalPrice] = useState(0);
  const [cityList, setCityList] = useState<City[]>([]);
  const [stationList, setStationList] = useState<City[]>([]);
  const [bikeList, setBikeList] = useState([] as any);

  const [checkoutPageData, setCheckoutPageData] = useState({
    bikeDetails: initialBikeDetails,
    pricing: initialPricing,
    delivery: initialDelivery,
    accessories: [],
  });
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookingValues((prevValues: any) => ({
      ...prevValues,
      homeDelivery: e.target.checked,
    }));
  };
  const handleHomeDeliveryAddress = (e: any) => {
    const { value } = e.target;
    setBookingValues((prevValues: any) => ({
      ...prevValues,
      homeDeliveryAddress: value,
    }));
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;

      if (start && end && end.isBefore(start)) {
        message.error("End date cannot be earlier than start date.");
        return;
      }

      setBookingValues({
        ...bookingValues,
        from: start?.toISOString() || "",
        to: end?.toISOString() || "",
      });
    } else {
      setBookingValues({
        ...bookingValues,
        from: "",
        to: "",
      });
    }
  };

  const getCities = async () => {
    const getData = await API.admin.GetCities();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setCityList(getData.data?.data);
    console.log(getData.data?.data, "getData.data?.data");
  };
  const getStationList = async () => {
    if (bookingValues.city && pickupDate && dropoffDate) {
      const getStationData = await API.admin.GetStationList({
        city: [bookingValues.city],
        pickupDate: pickupDate,
        dropoffDate: dropoffDate,
      });

      if (!API.validators.checkSuccessCode(getStationData)) {
        return message.error("Technical error");
      }
      setStationList(getStationData.data?.data);
    }
  };
  const bikeDetailsApi = async (bikeId: string, from: string, to: string) => {
    try {
      const formData = {
        bikeId,
        from: new Date(bookingValues.from),
        to: new Date(bookingValues.to),
      };
      const getCheckoutDataData = await API.admin.CheckoutPage(formData);
      if (!API.validators.checkSuccessCode(getCheckoutDataData)) {
        console.error("Technical error");
      }
      setCheckoutPageData(getCheckoutDataData.data?.data);
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  const cityAvailableBikes = async () => {
    if (
      bookingValues.city &&
      pickupDate &&
      dropoffDate &&
      bookingValues.stationId
    ) {
      const availableBikes = await API.admin.SelectedCityAvailableBikes({
        city: [bookingValues.city],
        pickupDate: pickupDate,
        dropoffDate: dropoffDate,
        station: [bookingValues.stationId],
      });
      if (!API.validators.checkSuccessCode(availableBikes)) {
        return console.error("Technical error");
      }
      setBikeList(availableBikes.data.data);
    }
  };

  const handleVariantSelect = (variantId: string) => {
    const variant = bikeList.find((bike: any) => bike.variantId === variantId);
    console.log(variant);
    const selectedStation = variant.stations.find(
      (item: any) => item.stationId === bookingValues.stationId
    );
    if (selectedStation) {
      const { bikeId } = selectedStation;
      setBookingValues({
        ...bookingValues,
        bikeId: bikeId,
      });
    }
  };

  useEffect(() => {
    if (
      bookingValues.bikeId &&
      bookingValues.stationId &&
      bookingValues.from &&
      bookingValues.to
    ) {
      bikeDetailsApi(
        bookingValues.bikeId,
        bookingValues.from as string,
        bookingValues.to as string
      );
    }
  }, [bookingValues.bikeId]);
  useEffect(() => {
    getCities();
  }, []);
  useEffect(() => {
    getStationList();
  }, [bookingValues.city, bookingValues.to]);

  useEffect(() => {
    cityAvailableBikes();
  }, [bookingValues.stationId]);

  const deliveryModify = checkoutPageData?.delivery?.deliveryCharges;
  const homeDeliveryTotalPrice =
    bookingValues.homeDeliveryData === HomeDeliveryEnum.UpTo10
      ? deliveryModify?.upto10
      : deliveryModify?.upto20;
  const totalPriceTobePaid =
    checkoutPageData?.pricing?.price +
    accessoriesTotalPrice +
    (bookingValues?.homeDelivery ? homeDeliveryTotalPrice : 0);

  const pickupDate = bookingValues.from ? bookingValues.from : null;
  const dropoffDate = bookingValues.to ? bookingValues.to : null;

  console.log(bikeList, bookingValues, "bikeList");
  const disabledBookingBtn = () => {
    const isHomeDeliveryAddressValid =
      !bookingValues.homeDelivery ||
      (bookingValues.homeDelivery &&
        bookingValues.homeDeliveryAddress.length >= 4);
    return (
      !bookingValues.bikeId ||
      !bookingValues.stationId ||
      !bookingValues.from ||
      !bookingValues.to ||
      !isHomeDeliveryAddressValid
    );
  };
  return (
    <div className={style["container"]}>
      <h1>Booking</h1>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container-full"]}>
          <label htmlFor="city">
            City<span>*</span>
          </label>
          <Select
            onChange={(value) => {
              setBookingValues((prevValues: any) => ({
                ...prevValues,
                city: value, // Update the city field
                stationID: "Select Station Name", // Reset the station selection
                dateRange: null as [Dayjs, Dayjs] | null,
              }));
            }}
            placeholder="Select City Name"
          >
            {cityList.map((city) => (
              <Option key={city._id} value={city._id} label={city.name}>
                <div>{city.name}</div>
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className={style["wrap-inp"]}>
        <div className={style["inp-container-full"]}>
          <label htmlFor="station">
            Date Range<span>*</span>
          </label>
          <RangePicker
            showTime
            disabled={!bookingValues.city}
            disabledDate={disabledPastDate}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            onChange={handleDateChange}
            size="small"
            value={
              bookingValues.from && bookingValues.to
                ? [dayjs(bookingValues.from), dayjs(bookingValues.to)] // Parsing ISO strings back to Dayjs objects
                : null
            }
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container-full"]}>
          <label htmlFor="station">
            Station<span>*</span>
          </label>
          <Select
            placeholder="Select Station Name"
            disabled={!bookingValues.city || !pickupDate || !dropoffDate}
            onChange={(value) => {
              setBookingValues({ ...bookingValues, stationId: value });
            }}
            value={bookingValues.stationId}
          >
            {stationList.map((station) => (
              <Option
                key={station._id}
                value={station._id}
                label={station.name}
              >
                <div>{station.name}</div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container-full"]}>
          <label htmlFor="station">
            Bike<span>*</span>
          </label>

          <Select
            disabled={
              !bookingValues.city ||
              !bookingValues.stationId ||
              !pickupDate ||
              !dropoffDate
            }
            onChange={handleVariantSelect}
            value={bookingValues.bikeId}
            placeholder="Select Bike"
          >
            {bikeList.map((bike: any) => (
              <Option
                key={bike.variantId}
                value={bike.variantId}
                label={bike.name}
              >
                <div>{bike.name}</div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {bookingValues.bikeId && bookingValues.from && bookingValues.to && (
        <div className={style["wrap-inp"]}>
          <div className={style["inp-container-full"]}>
            <RadioBtnList
              // items={
              //   checkoutPageData.pricing.acceptPartialPayment
              //     ? paymentType
              //     : onlyFullPaymentType
              // }
              items={paymentType}
              title="Payment Option"
              setFilterValues={setBookingValues}
              filterValues={bookingValues}
              keyValue="paymentOption"
              notBottomBorder
            />
            {/* {checkoutPageData?.delivery?.accept && ( */}
            <>
              <hr className="border-1 border-gray-300 border-dashed w-full mt-4 mb-2 " />
              <SwitchBtn
                checked={bookingValues?.homeDelivery}
                onChange={handleSwitchChange}
                classNameValue="mt-2"
                title="Home Delivery"
              />
              {bookingValues.homeDelivery && (
                <>
                  <RadioBtnListWithPrice
                    items={homeDeliveryOptions(
                      checkoutPageData?.delivery?.deliveryCharges
                    )}
                    dynamicKey={{
                      label: "label",
                      value: "value",
                      name: "name",
                    }}
                    title=""
                    setSelectedItem={setBookingValues}
                    selectedItem={bookingValues}
                    keyValue="homeDeliveryData"
                    noBottomBorder
                  />
                  <TextArea
                    id="address"
                    placeholder="Please Enter Home Delivery Address"
                    // noOfRows={2}
                    className="mt-4"
                    value={bookingValues.homeDeliveryAddress}
                    onChange={handleHomeDeliveryAddress}
                  />
                </>
              )}
            </>
            {/* )} */}
            {/* {checkoutPageData?.accessories.length > 0 && ( */}
            <>
              <hr className="border-1 border-gray-300 border-dashed w-full mt-4 mb-2 " />
              <CheckBoxGroupWithQuantity
                items={checkoutPageData?.accessories}
                checkedItems={bookingValues}
                setCheckedItems={setBookingValues}
                keyValue="accessories"
                title="Accessories"
                dynamicKey={{ label: "name", value: "price" }}
                noBottomBorder
                setAccessoriesTotalPrice={setAccessoriesTotalPrice}
                accessoriesTotalPrice={accessoriesTotalPrice}
              />
            </>
            {/* )} */}
            <hr className="border-1 border-gray-300 border-dashed w-full mt-4 mb-2 relative z-20" />
            <label>Price Info</label>
            <div className={style["price-container"]}>
              {PriceInfo.map((item) => {
                let price = "";

                switch (item.id) {
                  case 1:
                    price = `₹${checkoutPageData?.pricing?.price}`;
                    break;
                  case 2:
                    price = `₹${formatNumber(accessoriesTotalPrice)}`;
                    break;
                  case 3:
                    price = `₹${formatNumber(homeDeliveryTotalPrice)}`;
                    break;

                  default:
                    price = "NAN";
                }

                // Conditionally render the item with id 3 based on homeDelivery
                if (item.id === 3 && !bookingValues.homeDelivery) {
                  return null;
                }

                if (item.id === 2 && accessoriesTotalPrice === 0) {
                  return null;
                }

                return (
                  <div className={style["wrap-price"]} key={item.id}>
                    <p>{item.title}</p>
                    <p>{price}</p>
                  </div>
                );
              })}
              {bookingValues.paymentOption ===
              PaymentTypeEnum.PartialPayment ? (
                <>
                  <div className={style["wrap-price"]}>
                    <p>Amount To Be Paid Now</p>
                    <p>{`₹${formatNumber((totalPriceTobePaid * 30) / 100)}`}</p>
                  </div>
                  <div className={style["wrap-price"]}>
                    <p>Amount To Be Paid At Picup Time</p>
                    <p>{`₹${formatNumber((totalPriceTobePaid * 70) / 100)}`}</p>
                  </div>
                </>
              ) : (
                <div className={style["wrap-price"]}>
                  <p>Amount To Be Paid Now</p>
                  <p>{`₹${formatNumber(totalPriceTobePaid)}`}</p>
                </div>
              )}
              <div className={style["wrap-price"]}>
                <div>
                  <p>Refundable Deposite:</p>
                  <p style={{ marginTop: "-8px" }}>
                    (To Be Paid At Picup Time)
                  </p>
                </div>
                <p>{`₹${checkoutPageData?.pricing?.securityDepositAmount}`}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton
          color="green"
          disabledValue={disabledBookingBtn()}
          onClick={() => setMode("order")}
        >
          Book Now
        </CommonButton>

        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
}

export default CreateBooking;
