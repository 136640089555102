import styles from "./radioBtnList.module.scss";

type RadioBtnListFilterValues = {
  transmissionType?: string;
  payOnDelivery?: string;
  paymentMethod?: string;
  homeDelivery?: string;
  paymentOption?: string;
};

const RadioBtnList = ({
  items,
  title,
  setFilterValues,
  filterValues,
  keyValue,
  notBottomBorder,
}: {
  items: string[];
  title: string;
  setFilterValues: any;
  filterValues: any;
  keyValue: keyof RadioBtnListFilterValues;
  notBottomBorder?: boolean;
}) => {
  const handleCheckboxChange = (item: string) => {
    setFilterValues({
      ...filterValues,
      [keyValue]: item?.toLowerCase().replace(/-/g, ""),
    });
  };

  return (
    <div className={styles["container"]}>
      <p className={styles.title}>{title}</p>
      {!notBottomBorder && <hr className={styles["borderBottom"]} />}

      <div className={styles["list"]}>
        {items.map((item, index) => (
          <div key={index} className={styles["listItem"]}>
            <input
              type="radio"
              name={title}
              checked={
                filterValues[keyValue] === item?.toLowerCase().replace(/-/g, "")
              }
              onChange={() => handleCheckboxChange(item)}
              className={styles["radioInput"]}
            />
            <p className={styles.extraSmallTextBlack}>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioBtnList;
