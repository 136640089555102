import { ReactComponent as MotorcycleIcon } from "../assests/nav/motorcycle.svg";
import { ReactComponent as AccessoriesIcon } from "../assests/nav/accessories.svg";
import { ReactComponent as HomeIcon } from "../assests/nav/home.svg";
import { Layout, Menu, theme } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import style from "../style/layouts.module.scss";
import TopHeader from "../components/TopHeader";
enum ROUTE {
  HOME = "/",
  BIKES = "/bikes",
  ACCESSORIES = "/accessories",
  PARTNER_BIKESB_LOCKS = "/blocks",
}

const DASHBOARD_NAV_BAR = [
  {
    label: "Home",
    key: ROUTE.HOME,
    icon: <HomeIcon />,
  },
  {
    label: "Bikes",
    key: ROUTE.BIKES,
    icon: <MotorcycleIcon style={{ width: "30px" }} />,
  },
  {
    label: "Accessories",
    key: ROUTE.ACCESSORIES,
    icon: <AccessoriesIcon />,
  },
  {
    label: "Blocks",
    key: ROUTE.PARTNER_BIKESB_LOCKS,
    icon: <AccessoriesIcon />,
  },
];

const { Header, Sider, Content } = Layout;
const basePath = "/partner/dashboard";
const getPathFromKey = (key: String) => {
  const keyValue = DASHBOARD_NAV_BAR.filter((entry) => entry.key === key)?.[0]
    ?.key;
  return `${basePath}${keyValue}`;
};

const PartnerLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  return (
    <Layout className={style["main-container"]}>
      <Sider>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          items={[...DASHBOARD_NAV_BAR]}
          onSelect={({ key }) => {
            navigate(getPathFromKey(key));
          }}
        />
      </Sider>
      <Layout className={style["left-content"]}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className={style["wrap-header"]}>
            <TopHeader isPartner />
          </div>
        </Header>
        <Content
          style={{
            padding: 24,
            background: "transparent",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PartnerLayout;
