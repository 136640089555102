import React, { useEffect, useState } from "react";
import type { DatePickerProps } from "antd";
import API from "../api";
import style from "../style/tableCol.module.scss";
import CommonButton from "../components/CommonButton";
import BookingTable from "./bookingTable";
import CreateBooking from "./CreateBooking";
import CreateOrder from "./CreateOrder";
import {
  City,
  initialBookingValues,
  BookingData,
} from "../utils/const/bookingConst";
import { DatePicker, message, Select, Space, Input } from "antd";

import dayjs, { Dayjs } from "dayjs";
import { disabledPastDate } from "../utils/disabledPastDate";
import { ConvertFirstLetter } from "../utils/ConvertFirstLetter";
import BookingDetailsDrawer from "./BookingDetailsDrawer";

function Booking() {
  const [bookingDataByPickup, setBookingDataByPickup] = useState<BookingData[]>(
    []
  );
  const [bookingDataByDropoff, setBookingDataByDropoff] = useState<
    BookingData[]
  >([]);
  const [selectedData, setSelectedData] = useState<BookingData[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [pickupDate, setPickupDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [dropoffDate, setDropoffDate] = useState(dayjs().format("YYYY-MM-DD"));

  const getBookingDataByPickup = async () => {
    try {
      const response = await API.admin.GetBookingData({
        pickupFrom: dayjs(pickupDate).startOf("day"),
        pickupTo: dayjs(pickupDate).endOf("day"),
      });
      if (!API.validators.checkSuccessCode(response)) {
        message.error("Technical error while fetching booking data");
        return;
      }

      setBookingDataByPickup(response.data?.data || []);
    } catch (error) {
      message.error("Error fetching booking data");
    }
  };

  const getBookingDataByDropoff = async () => {
    try {
      const response = await API.admin.GetBookingData({
        // pickupTo: dropoffDate,
        dropoffFrom: dayjs(dropoffDate).startOf("day"),
        dropoffTo: dayjs(dropoffDate).endOf("day"),
      });
      if (!API.validators.checkSuccessCode(response)) {
        message.error("Technical error while fetching booking data");
        return;
      }

      setBookingDataByDropoff(response.data?.data || []);
    } catch (error) {
      message.error("Error fetching booking data");
    }
  };

  const onChangeByPickup: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string") {
      setPickupDate(dateString); // Ensure it's a string
    }
  };

  const onChangeByDropoff: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string") {
      setDropoffDate(dateString); // Ensure it's a string
    }
  };

  useEffect(() => {
    getBookingDataByPickup();
  }, [pickupDate]);

  useEffect(() => {
    getBookingDataByDropoff();
  }, [dropoffDate]);

  return (
    <div>
      {showDrawer && (
        <BookingDetailsDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedData={selectedData}
        />
      )}
      <div className={style["wrap-img-name"]}>
        <p className={style["heading"]}>By Pickup</p>
        <DatePicker onChange={onChangeByPickup} />
      </div>
      <BookingTable
        data={bookingDataByPickup}
        setShowDrawer={setShowDrawer}
        setSelectedData={setSelectedData}
        isPickup
      />
      <div className={style["wrap-img-name"]}>
        <p className={style["heading"]}>By Dropoff</p>
        <DatePicker onChange={onChangeByDropoff} />
      </div>
      <BookingTable
        data={bookingDataByDropoff}
        setShowDrawer={setShowDrawer}
        setSelectedData={setSelectedData}
        isDropOff
      />
    </div>
  );
}

export default Booking;
