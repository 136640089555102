import styles from "./radioBtnListWithPrice.module.scss";
interface RadioBtnListProps {
  items: Array<Record<string, any>>;
  selectedItem: any;
  setSelectedItem: any;
  keyValue: string;
  dynamicKey: {
    label: string;
    value: string;
    name: string;
  };
  showSearch?: boolean;
  title: string;
  noBottomBorder?: boolean;
}

const RadioBtnListWithPrice: React.FC<RadioBtnListProps> = ({
  items,
  setSelectedItem,
  keyValue,
  selectedItem,
  title,
  dynamicKey,
  noBottomBorder,
}) => {
  const getItemValue = (
    item: string | Record<string, any>,
    dynamicKey?: string | string[]
  ): string => {
    if (typeof item === "string") {
      return item;
    } else if (typeof dynamicKey === "string") {
      return item[dynamicKey] || "";
    } else if (Array.isArray(dynamicKey)) {
      return dynamicKey.map((key) => item[key]).join(" ");
    } else {
      return ""; // Handle the case where dynamicKey is not provided
    }
  };

  const handleRadioChange = (item: Record<string, any>) => {
    const itemName = getItemValue(item, dynamicKey?.name);
    setSelectedItem({ ...selectedItem, [keyValue]: itemName });
  };

  return (
    <div className={styles["container"]}>
      {!noBottomBorder && <hr />}
      {items.map((item) => {
        const itemLabel = getItemValue(item, dynamicKey?.label);
        const itemValue = getItemValue(item, dynamicKey?.value);
        const itemName = getItemValue(item, dynamicKey?.name);

        return (
          <div key={itemName} className={styles["itemContainer"]}>
            <div className={styles["wrap-inp"]}>
              <input
                type="radio"
                name={title}
                checked={selectedItem[keyValue] === itemName}
                onChange={() => handleRadioChange(item)}
                className={styles["radioInput"]}
              />
              <label htmlFor={itemLabel} className={styles["label"]}>
                <p>{itemLabel}</p>
              </label>
            </div>
            <p className={styles["price"]}>{`₹${itemValue ? itemValue : 0}`}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RadioBtnListWithPrice;
