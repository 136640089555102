import { Input, InputRef } from "antd";
import React, { useEffect, useState, useRef } from "react";
const getNestedValue = (obj: any, key: string) => {
  return key.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const SearchFeature = ({
  originalData,
  setData,
  keyData,
  placeholder,
}: {
  originalData: any;
  setData: any;
  keyData: string;
  placeholder?: string;
}) => {
  const [searchItem, setSearchItem] = useState("");
  const debounce = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<InputRef>(null);

  const handleSearchItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchItem(value);

    if (debounce.current) {
      clearTimeout(debounce.current);
    }

    // Debouncing the search input
    debounce.current = setTimeout(() => {
      searchCities(value);
    }, 500);
  };

  const searchCities = (query: string) => {
    if (query) {
      const filteredData = originalData.filter((item: any) => {
        const nestedValue = getNestedValue(item, keyData); // Get the dynamic key value
        return nestedValue
          ?.toString()
          ?.toLowerCase()
          .includes(query.toLowerCase());
      });
      setData(filteredData);
    } else {
      setData(originalData);
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef?.current?.focus();
  }, []);

  return (
    <Input
      placeholder={placeholder ? placeholder : "Search By"}
      style={{ width: "400px" }}
      value={searchItem}
      onChange={handleSearchItem}
      ref={inputRef}
    />
  );
};

export default SearchFeature;
