import { useState, useEffect } from "react";
import API from "../api";
import { AxiosResponse } from "axios";
import CommonButton from "../components/CommonButton";
import {
  Empty,
  Table,
  Select,
  Input,
  Drawer,
  Space,
  Switch,
  InputNumber,
} from "antd";
import style from "../style/twoColForm.module.scss";
import partnerHubs from "./partnerHubs.module.scss";
import { DeleteTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import {
  StationInterface,
  defaultStationData,
  stationCol,
  timeFormate,
  WeekDays,
} from "../Admin/partnersConst";
import {
  antdMessageError,
  antdMessageSuccess,
} from "../utils/antdMessageComponent";
const PartnerHubs = ({ id }: { id: any }) => {
  const [data, setData] = useState([] as StationInterface[]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("create" as "edit" | "create");
  const [cityData, setCityData] = useState(
    [] as { _id: string; name: string }[]
  );
  const [activeStationData, setActiveStationData] = useState(
    defaultStationData as StationInterface
  );

  useEffect(() => {
    (async () => {
      if (open) return;

      const cities = await API.admin.GetCities();
      if (!API.validators.checkSuccessCode(cities)) return;

      const modifiedCityData = cities.data?.data?.map((item: any) => {
        return {
          value: item?._id,
          label: item?.name,
        };
      });

      setCityData(modifiedCityData);
    })();
  }, [id, open]);

  const onClose = () => {
    setOpen(false);
  };

  // const onstationDelete = (value: StationInterface) => {
  //   //    setOpen(true)
  // };

  const onstationEdit = (value: StationInterface) => {
    console.log(value, "value");
    setMode("edit");
    setActiveStationData({ ...value, partner: id });
    setOpen(true);
  };

  const createStation = () => {
    setMode("create");
    setActiveStationData({ ...defaultStationData, partner: id });
    setOpen(true);
  };

  useEffect(() => {
    if (!id) return;

    if (open) return;

    (async () => {
      const stations = await API.admin.GetStations({ _id: id });

      if (!API.validators.checkSuccessCode(stations))
        return antdMessageError("Unable to fetch statiobs");

      const modifyStationData = [] as any;
      const stationResponse = stations.data?.data;
      for (let i = 0; i < stationResponse.length; i++)
        modifyStationData.push({
          key: stationResponse[i]?._id,
          name: stationResponse[i]?.name,
          phone_1: stationResponse[i]?.phone[0],
          phone_2: stationResponse[i]?.phone[1],
          action: (
            <a
              onClick={() => {
                onstationEdit(stationResponse[i]);
              }}
            >
              edit
            </a>
          ),
        });
      setData(modifyStationData);
    })();
  }, [id, open]);

  const saveStationData = async () => {
    let station;

    if (mode === "create") {
      delete activeStationData._id;
      station = await API.admin.CreateStation(activeStationData);
    }
    if (mode === "edit") {
      station = await API.admin.EditStation(
        activeStationData,
        activeStationData._id as string
      );
    }

    if (!API.validators.checkSuccessCode(station as AxiosResponse<any, any>))
      return antdMessageError("unable to save right now");

    antdMessageSuccess("saved successfully");
    setOpen(false);
  };
  const handleOptionForWeekDay = (day: any) => {
    // const newClosingDays = activeStationData.closingDays.includes(day)
    //   ? activeStationData.closingDays.filter(
    //       (selectedDay) => selectedDay !== day
    //     )
    //   : [...activeStationData.closingDays, day];

    // setActiveStationData({
    //   ...activeStationData,
    //   closingDays: newClosingDays,
    // });
    const isSelected = activeStationData.closingDays.includes(day);
    let newClosingDays;

    if (isSelected) {
      // If the day is already selected, remove it from the array
      newClosingDays = activeStationData.closingDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      // If the day is not selected, add it to the array
      newClosingDays = [...activeStationData.closingDays, day];
    }

    setActiveStationData({
      ...activeStationData,
      closingDays: newClosingDays,
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <CommonButton color="primary" onClick={() => createStation()}>
          Create Station
        </CommonButton>
      </div>

      {data?.length ? (
        <Table dataSource={data} columns={stationCol} pagination={false} />
      ) : (
        <Empty />
      )}

      <Drawer
        title="Modify"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        closable={false}
        extra={
          <Space>
            <CommonButton color="green" onClick={() => saveStationData()}>
              Save
            </CommonButton>
            <CommonButton color="secondary" onClick={onClose}>
              Cancel
            </CommonButton>
          </Space>
        }
      >
        <div
          className={style["container"]}
          style={{ width: "450px", padding: "unset", boxShadow: "none" }}
        >
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="name">Station</label>
              <Input
                value={activeStationData.name}
                name="name"
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div
              className={style["inp-container"]}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0px",
              }}
            >
              <label
                htmlFor="disabled"
                style={{ marginTop: "5px", marginRight: "8px" }}
              >
                Disable
              </label>
              <Switch
                value={activeStationData.disabled}
                onChange={(checked) =>
                  setActiveStationData({
                    ...activeStationData,
                    disabled: checked,
                  })
                }
              />
            </div>
          </div>
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="city">City</label>
              <Select
                value={activeStationData.city}
                placeholder={"select city"}
                onChange={(value) => {
                  setActiveStationData({ ...activeStationData, city: value });
                }}
                options={cityData}
              />
            </div>
            <div className={style["inp-container"]}>
              <label htmlFor="address">Address</label>
              <Input
                value={activeStationData.address}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    address: e.target.value,
                  });
                }}
                name="address"
              />
            </div>
          </div>
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="pincode">Pincode</label>
              <InputNumber
                value={activeStationData.pincode}
                name="pincode"
                onChange={(value) => {
                  setActiveStationData({
                    ...activeStationData,
                    pincode: Number(value),
                  });
                }}
              />
            </div>
            <div className={style["inp-container"]}>
              <label htmlFor="commision">Commision</label>
              <InputNumber
                value={activeStationData.share}
                onChange={(value) => {
                  setActiveStationData({
                    ...activeStationData,
                    share: Number(value) / 100,
                  });
                }}
                name="commision"
              />
            </div>
          </div>
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="landmark">landmark</label>
              <Input
                value={activeStationData.landmark}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    landmark: e.target.value,
                  });
                }}
                name="landmark"
              />
            </div>
            <div className={style["inp-container"]}>
              <label htmlFor="mapLink">Map Link</label>
              <Input
                value={activeStationData.map}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    map: e.target.value,
                  });
                }}
                name="mapLink"
              />
            </div>
          </div>
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="directions">Directions</label>
              <Input
                value={activeStationData.directions}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    directions: e.target.value,
                  });
                }}
                name="directions"
              />
            </div>
            <div className={style["inp-container"]}>
              <label htmlFor="email">Email</label>
              <Input
                value={activeStationData.email}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    email: e.target.value,
                  });
                }}
                name="email"
              />
            </div>
          </div>
          <div className={style["wrap-inp"]}>
            <div className={style["inp-container"]}>
              <label htmlFor="Phoneno1">Phone no 1</label>
              <Input
                value={activeStationData.phone?.[0]}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    phone: [e.target.value, activeStationData.phone?.[1]],
                  });
                }}
                name="Phoneno1"
              />
            </div>
            <div className={style["inp-container"]}>
              <label htmlFor="Phoneno2">Phone no 2</label>
              <Input
                value={activeStationData.phone?.[1]}
                onChange={(e) => {
                  setActiveStationData({
                    ...activeStationData,
                    phone: [activeStationData.phone?.[0], e.target.value],
                  });
                }}
                name="Phoneno2"
              />
            </div>
          </div>

          <h3 style={{ margin: "0px 0px -16px" }}>Opration</h3>
          <div
            className={style["day-inp-container"]}
            style={{ padding: "24px 24px 8px" }}
          >
            <div className={style["wrap-inp"]}>
              <div className={style["inp-container"]}>
                <label htmlFor="chargeupto20km">Price calculation</label>
                <Select
                  value={activeStationData.priceCalculation}
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      priceCalculation: value,
                    });
                  }}
                  options={[
                    { value: "HOUR", label: "Hour" },
                    { value: "DAY", label: "Day" },
                  ]}
                />
              </div>
              <div className={style["inp-container"]}>
                <div
                  className={style["wrap-disable"]}
                  style={{ marginTop: "24px" }}
                >
                  <label htmlFor="allowPartial" className={style["title"]}>
                    Partial Payment
                  </label>
                  <Switch
                    value={activeStationData.allowPartial}
                    onChange={(checked) =>
                      setActiveStationData({
                        ...activeStationData,
                        allowPartial: checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className={style["wrap-inp"]}>
              <div className={style["inp-container"]}>
                <label htmlFor="openingTime">Opening Time</label>
                <Select
                  value={activeStationData.openingTime}
                  placeholder={"Select Time"}
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      openingTime: value,
                    });
                  }}
                  options={timeFormate}
                />
              </div>
              <div className={style["inp-container"]}>
                <label htmlFor="closingTime">Closing Time</label>
                <Select
                  value={activeStationData.closingTime}
                  placeholder={"Select Time"}
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      closingTime: value,
                    });
                  }}
                  options={timeFormate}
                />
              </div>
            </div>
            <div className={style["wrap-inp"]}>
              <div className={style["inp-container"]}>
                <label htmlFor="openingTime">Closing Day</label>
                <div className={partnerHubs["days-container"]}>
                  {WeekDays.map((option: any) => (
                    <div
                      key={option.id}
                      // className={partnerHubs["days-item"]}
                      className={`${partnerHubs["days-item"]} ${
                        activeStationData.closingDays.includes(option.id)
                          ? partnerHubs["selected-day"]
                          : ""
                      }`}
                    >
                      <div onClick={() => handleOptionForWeekDay(option.id)}>
                        {option.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <h3 style={{ margin: "0px 0px -16px" }}>Delivery</h3>
          <div
            className={style["day-inp-container"]}
            style={{ padding: "24px 24px 8px" }}
          >
            <div className={style["wrap-inp"]}>
              <div className={style["inp-container"]}>
                <label htmlFor="TeliverypreparationTime">
                  Preparation time
                </label>
                <InputNumber
                  value={activeStationData.prepTime}
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      prepTime: Number(value),
                    });
                  }}
                  name="TeliverypreparationTime"
                />
              </div>
              <div className={style["inp-container"]}>
                <div
                  className={style["wrap-disable"]}
                  style={{ marginTop: "24px" }}
                >
                  <label htmlFor="delivery" className={style["title"]}>
                    Allow
                  </label>
                  <Switch
                    value={activeStationData.delivery}
                    onChange={(checked) =>
                      setActiveStationData({
                        ...activeStationData,
                        delivery: checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className={style["wrap-inp"]}>
              <div className={style["inp-container"]}>
                <label htmlFor="chargeupto10km">Charge UpTo 10 km</label>

                <InputNumber
                  value={activeStationData.deliveryCharge.upto10}
                  name="pincode"
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      deliveryCharge: {
                        ...activeStationData?.deliveryCharge,
                        upto10: Number(value),
                      },
                    });
                  }}
                />
              </div>
              <div className={style["inp-container"]}>
                <label htmlFor="chargeupto20km">Charge UpTo 20 km</label>
                <InputNumber
                  value={activeStationData.deliveryCharge.upto20}
                  name="pincode"
                  onChange={(value) => {
                    setActiveStationData({
                      ...activeStationData,
                      deliveryCharge: {
                        ...activeStationData?.deliveryCharge,
                        upto20: Number(value),
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <h3 style={{ margin: "0px 0px -16px" }}>
            Terms and conditions{" "}
            <div className={style["add-more"]}>
              <div
                onClick={() => {
                  const newdata = activeStationData?.tnc;
                  newdata.push("Tnc");
                  setActiveStationData({ ...activeStationData, tnc: newdata });
                }}
              >
                Add <PlusCircleTwoTone style={{ marginLeft: "8px" }} />
              </div>
            </div>
          </h3>
          <div
            className={style["day-inp-container"]}
            style={{ padding: "0px 24px 24px" }}
          >
            {activeStationData?.tnc?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div className={style["wrap-inp"]}>
                    <Input
                      style={{ marginTop: "24px", marginRight: "16px" }}
                      value={activeStationData.tnc?.[index]}
                      onChange={(e) => {
                        const array = activeStationData?.tnc;
                        array[index] = e.target.value;

                        setActiveStationData({
                          ...activeStationData,
                          tnc: array,
                        });
                      }}
                    />
                    <div
                      onClick={() => {
                        console.log(
                          activeStationData?.tnc.length,
                          "activeStationData"
                        );
                        if (activeStationData?.tnc.length === 1) {
                          return antdMessageError(
                            "At least One T&C should be present"
                          );
                        }
                        setActiveStationData({
                          ...activeStationData,
                          tnc: activeStationData.tnc.filter(
                            (key: any, i: any) => index !== i
                          ),
                        });
                      }}
                      style={{ marginTop: "24px" }}
                    >
                      <DeleteTwoTone />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PartnerHubs;
// Dyanmic Array
{
  /* <div>
  {Array.from(Array(24).keys()).map((value, index) => (
    <div key={index}>{index.toString().padStart(2, '0')}</div>
  ))}
</div> */
}
