import React, { useState, useEffect, useRef } from "react";
import API from "../api";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as DownOutlined } from "../assests/downOutlined.svg";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { Select, message } from "antd";
import style from "./topHeader.module.scss";
import { antdMessageError } from "../utils/antdMessageComponent";
const TopHeader = ({
  isPartner,
  setCollapsed,
  collapsed,
}: {
  isPartner?: boolean;
  setCollapsed?: any;
  collapsed?: boolean;
}) => {
  const navigate = useNavigate();
  const authDetails = storage.getAuthDetails();
  const ref = useRef<any>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [stationData, setStationData] = useState([] as any);
  const handleClickOutside = (e: any) => {
    if (!ref?.current?.contains(e?.target)) {
      setOpenMenu(false);
    }
  };

  const logout = async () => {
    if (isPartner) {
      const logoutResponse = await API.auth.PartnerLogout({
        sessionId: authDetails.sessionId || "",
      });
      if (!API.validators.checkSuccessCode(logoutResponse))
        return antdMessageError("Unable to logout");
      storage.clearAuthDetails();
      storage.clearSelectedStation();
      return navigate("/partner");
    } else {
      const logoutResponse = await API.auth.AdminLogout({
        sessionId: authDetails.sessionId || "",
      });
      if (!API.validators.checkSuccessCode(logoutResponse))
        return antdMessageError("Unable to logout");
      storage.clearAuthDetails();
      return navigate("/admin");
    }
  };
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const title = params.get("title");
  const fetchStations = async () => {
    if (isPartner) {
      const stations = await API.partner.GetStations({ _id: authDetails._id });
      const stationData = stations.data?.data;
      if (!API.validators.checkSuccessCode(stations))
        return message.error("Unable to fetch statiobs");
      setStationData(stationData);
      if (!storage.getSelectedStation().id) {
        storage.setSelectedStation({
          id: stationData?.[0]?._id,
          name: stationData?.[0]?.name,
        });
      }
    }
  };
  useEffect(() => {
    if (isPartner) {
      fetchStations();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const defaultVal = storage.getSelectedStation().name;
  return (
    <div className={style["container"]}>
      <div
        className={style["left-container"]}
        onClick={() => setCollapsed(!collapsed)}
      >
        Logo
      </div>
      <div className={style["middle-container"]}>
        {isPartner && (
          <Select
            value={defaultVal}
            className={style["select-box"]}
            onChange={(value) => {
              const [id, name] = value?.split("++");
              storage.setSelectedStation({ id, name });
              window.location.reload();
            }}
          >
            {stationData.map((e: { _id: string; name: string }) => {
              return (
                <option key={e._id} value={`${e._id}++${e.name}`}>
                  {e.name}
                </option>
              );
            })}
          </Select>
        )}
      </div>

      <div className={style["right-container"]}>
        <div className={style["wrap-img"]}>
          <UserOutlined className={style["antd-user-outline"]} />
        </div>

        <div className={style["client-name-container"]}>
          <div className={style["client-name"]}>
            {authDetails.userName ? authDetails.userName : "Client Name"}
          </div>
          <div className={style["client-title"]}>
            {isPartner ? "Partner" : "Admin"}
          </div>
        </div>
        <DownOutlined
          className={style["drop-down-icon"]}
          onClick={() => {
            setOpenMenu(true);
          }}
        />
      </div>
      {openMenu && (
        <div className={style["menu-container"]} ref={ref}>
          <div className={style["items"]} onClick={logout}>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
