import React, { useState, useEffect } from "react";
import CustomeDropDownForNumber from "./CustomeDropDownForNumber";
import { formatNumber } from "./formatNumber";
import styles from "./checkBoxGroupWithQuantity.module.scss";
interface CheckboxGroupProps {
  items: Array<Record<string, any>>;
  checkedItems: any;
  setCheckedItems: any;
  keyValue: string;
  dynamicKey?: {
    label: string; // Key to use for disp=-aying text
    value: string; // Key to use for selection value
  };
  title: string;
  noBottomBorder?: boolean;
  setAccessoriesTotalPrice?: any;
  accessoriesTotalPrice?: number;
}

const CheckBoxGroupWithQuantity: React.FC<CheckboxGroupProps> = ({
  items,
  setCheckedItems,
  keyValue,
  checkedItems,
  title,
  dynamicKey,
  noBottomBorder,
  setAccessoriesTotalPrice,
  accessoriesTotalPrice,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const getItemValue = (
    item: string | Record<string, any>,
    dynamicKey?: string | string[]
  ): string => {
    if (typeof item === "string") {
      return item;
    } else if (typeof dynamicKey === "string") {
      return item[dynamicKey] || "";
    } else if (Array.isArray(dynamicKey)) {
      return dynamicKey.map((key) => item[key]).join(" ");
    } else {
      return ""; // Handle the case where dynamicKey is not provided
    }
  };
  const handleCheckboxChange = (item: any) => {
    // Extract the array for the given keyValue from checkedItems
    const currentItems = checkedItems?.[keyValue] || [];

    // Check if the item already exists in the array
    const itemExists = currentItems.some(
      (checkedItem: any) => checkedItem.id === item.id
    );

    if (itemExists) {
      // If the item exists, remove it
      const updatedItems = currentItems.filter(
        (checkedItem: any) => checkedItem.id !== item.id
      );
      setCheckedItems({
        ...checkedItems,
        [keyValue]: updatedItems,
      });
    } else {
      // If the item does not exist, add it
      setCheckedItems({
        ...checkedItems,
        [keyValue]: [
          ...currentItems,
          { id: item.id, qty: 1, price: item?.price },
        ],
      });
    }
  };

  const filteredItems = items?.filter((item) => {
    const itemName = getItemValue(item, dynamicKey?.value);
    return itemName;
  });

  const handleOptionChange = (selectedItem: any, item: any) => {
    const updatedAccessories =
      checkedItems[keyValue]?.map((accessory: any) => {
        if (accessory.id === item.id) {
          return { ...accessory, qty: selectedItem };
        }
        return accessory;
      }) || [];

    setCheckedItems({
      ...checkedItems,
      [keyValue]: updatedAccessories,
    });
  };
  useEffect(() => {
    const total = checkedItems.accessories?.reduce(
      (acc: number, item: any) => acc + item.qty * item.price,
      0
    );
    setAccessoriesTotalPrice(total);
  }, [checkedItems]);
  return (
    <div className={styles["container"]}>
      <p className={styles["title"]}>{title}</p>
      <div className="w-full space-y-4">
        <div className={styles["header"]}>
          <div className={styles["first-col"]} />
          <div className={styles["second-col"]}>
            <p>Price</p>
            <p>Quantity</p>
            <p>Total Price</p>
          </div>
        </div>

        {!noBottomBorder && <hr />}
        {filteredItems.map((item) => {
          const itemLabel = getItemValue(item, dynamicKey?.label);
          const itemValue = getItemValue(item, dynamicKey?.value);
          const checkedVal = checkedItems[keyValue]?.find(
            (accessory: any) => accessory.id === item.id
          );
          const totalPriceModify = checkedVal
            ? Number(itemValue) * Number(checkedVal.qty)
            : 0;

          // setTotalPrice(totalPrice+totalPriceModify)

          return (
            <div className={styles["wrap-first-second-col"]} key={itemValue}>
              <div className={styles["first-col"]}>
                <input
                  type="checkbox"
                  id={itemValue}
                  checked={checkedVal?.id}
                  onChange={() => handleCheckboxChange(item)}
                  className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                />
                <label htmlFor={itemValue}>
                  <p>{itemLabel}</p>
                </label>
              </div>

              <div className={styles["second-col"]}>
                <p>₹{formatNumber(Number(itemValue))}</p>

                <CustomeDropDownForNumber
                  option={[1, 2]}
                  selectedOption={checkedVal?.qty}
                  onOptionChange={(selectedItem: any) =>
                    handleOptionChange(selectedItem, item)
                  }
                />
                <p>₹{formatNumber(totalPriceModify)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CheckBoxGroupWithQuantity;
