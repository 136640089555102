import React, { useState, useRef, useEffect } from "react";
import API from "../api";
import { useNavigate } from "react-router-dom";
import style from "./loginForm.module.scss";
import { antdMessageError } from "../utils/antdMessageComponent";
import { storage, SUB_DOMAIN_TYPE } from "../utils/storage";
const LoginForm = ({
  isAdmin,
  isPatner,
}: {
  isAdmin?: boolean;
  isPatner?: boolean;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    buttonRef?.current?.focus();
  }, []);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && buttonRef.current && email && password) {
      buttonRef?.current?.click();
    }
  };
  const onFinish = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (isAdmin) {
      const loginResponse = await API.auth.AdminLogin({ email, password });

      if (!API.validators.checkSuccessCode(loginResponse))
        return antdMessageError("Invalid Credentials");

      const loginResponseData = loginResponse.data.data as {
        token: string;
        sessionId: string;
        expiresAt: string;
        version: number;
        userName: string;
      };
      storage.setAuthDetails({
        userType: SUB_DOMAIN_TYPE.ADMIN,
        token: loginResponseData.token,
        expiresAt: loginResponseData.expiresAt,
        version: loginResponseData.version,
        sessionId: loginResponseData.sessionId,
        userName: loginResponseData.userName,
      });
      navigate("/admin/dashboard");
    }
    if (isPatner) {
      const loginResponse = await API.auth.PartnerLogin({ email, password });
      if (!API.validators.checkSuccessCode(loginResponse))
        return antdMessageError("Invalid Credentials");

      const loginResponseData = loginResponse.data.data as {
        token: string;
        sessionId: string;
        expiresAt: string;
        version: number;
        partnerDetails: { username: string; _id: string };
      };
      storage.setAuthDetails({
        userType: SUB_DOMAIN_TYPE.PARTNER,
        token: loginResponseData.token,
        expiresAt: loginResponseData.expiresAt,
        version: loginResponseData.version,
        sessionId: loginResponseData.sessionId,
        userName: loginResponseData.partnerDetails.username,
        _id: loginResponseData.partnerDetails._id,
      });
      navigate("/partner/dashboard");
    }
  };
  return (
    <>
      <div className={style["container"]}>
        <div className={style["glass-form"]}>
          <h1 className={style["heading"]}>
            {isAdmin ? "Admin Login Page" : "Partner Login Page"}
          </h1>
          <div className={style["wrap-form"]}>
            <label htmlFor="name" className={style["form-label"]}>
              User Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={style["form-input"]}
              required
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyPress}
            />

            <label htmlFor="password" className={style["form-label"]}>
              Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className={style["form-input"]}
              required
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress}
            />

            <a href="https:/fb.com" className={style["forget-password"]}>
              Forgot Password?
            </a>

            <div
              className={style["form-button"]}
              onClick={() => onFinish({ email: email, password: password })}
              ref={buttonRef}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginForm;
