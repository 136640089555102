import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AntTable from "../components/CommonTable";
import CommonButton from "../components/CommonButton";
import API from "../api";
import { AxiosResponse } from "axios";
import {
  DatePicker,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  TableProps,
  message,
} from "antd";
import style from "../style/twoColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import { storage } from "../utils/storage";
import SearchFeature from "./SearchFeture";

interface RentCalculation {
  disabled: boolean;
  price?: number;
  kmLimit?: number;
}

interface DaySpecificRentCalculation extends RentCalculation {
  weekendPrice: {
    disabled: boolean;
    price?: number;
    kmLimit?: number;
  };
}

export interface Data {
  _id?: string;
  name: string;
  number: string;
  bikeVariant: string;
  station: string;
  mfgYear: string;
  speedLimit: string;
  timePenalty: number;
  distancePenalty: number;
  securityDepositAmount: number;
  rentCalculations: {
    halfDay: DaySpecificRentCalculation;
    oneDay: DaySpecificRentCalculation;
    oneWeek: RentCalculation;
    fifteenDays: RentCalculation;
    oneMonth: RentCalculation;
    threeMonths: RentCalculation;
    sixMonths: RentCalculation;
  };
}

const defaultData = {
  _id: "",
  name: "",
  number: "",
  bikeVariant: "",
  station: "",
  mfgYear: "",
  speedLimit: "",
  timePenalty: 0,
  distancePenalty: 0,
  securityDepositAmount: 0,
  rentCalculations: {
    halfDay: {
      disabled: false,
      price: 0,
      kmLimit: 0,
      weekendPrice: {
        disabled: false,
        price: 0,
        kmLimit: 0,
      },
    },
    oneDay: {
      disabled: false,
      price: 0,
      kmLimit: 0,
      weekendPrice: {
        disabled: false,
        price: 0,
        kmLimit: 0,
      },
    },
    oneWeek: {
      disabled: false,
      price: 0,
      kmLimit: 0,
    },
    fifteenDays: {
      disabled: false,
      price: 0,
      kmLimit: 0,
    },
    oneMonth: {
      disabled: false,
      price: 0,
      kmLimit: 0,
    },
    threeMonths: {
      disabled: false,
      price: 0,
      kmLimit: 0,
    },
    sixMonths: {
      disabled: false,
      price: 0,
      kmLimit: 0,
    },
  },
};

function CreateEditBike({
  mode,
  setMode,
  data,
  id,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: Data;
  id?: string;
}) {
  const [formDataD, setFormDataD] = useState(data);
  const [bikeData, setBikeData] = useState(
    [] as { _id: string; name: string }[]
  );
  console.log(data, "data");

  useEffect(() => {
    (async () => {
      const bikes = await API.partner.GetBikesVariant();
      if (!API.validators.checkSuccessCode(bikes)) return;

      const modifiedBikeData = bikes.data?.data?.map((item: any) => {
        return {
          value: item?._id,
          label: item?.name,
        };
      });

      setBikeData(modifiedBikeData);
    })();
  }, []);

  const onSave = async () => {
    if (!formDataD.name) return message.error("Name is required");
    formDataD.station = storage.getSelectedStation().id || "";
    let createBike;
    if (mode === "create") {
      delete formDataD._id;

      createBike = await API.partner.CreateBike(formDataD);
    }
    if (mode === "edit")
      createBike = await API.partner.EditBike(
        formDataD,
        formDataD._id as string
      );

    if (
      API.validators.checkSuccessCode(createBike as AxiosResponse<any, any>)
    ) {
      console.log(createBike);
      message.success("Details uploaded successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  return (
    <div>
      <div className={style["container"]}>
        <h1>Add Bike Details</h1>
        <div className={style["wrap-inp"]}>
          <div className={style["inp-container"]}>
            <label htmlFor="name">
              Bike Name<span>*</span>
            </label>
            <Input
              value={formDataD.name}
              onChange={(e) => {
                setFormDataD({ ...formDataD, name: e.target.value });
              }}
              name="name"
            />
          </div>
          <div className={style["inp-container"]}>
            <label htmlFor="bike">
              Bike Modal<span>*</span>
            </label>
            <Select
              value={formDataD.bikeVariant}
              placeholder={"select Bike"}
              onChange={(value) => {
                setFormDataD({ ...formDataD, bikeVariant: value });
              }}
              options={bikeData}
            />
          </div>
        </div>
        <div className={style["wrap-inp"]}>
          <div className={style["inp-container"]}>
            <label htmlFor="bikeNumber">
              Bike Number <span>*</span>
            </label>
            <Input
              value={formDataD.number}
              onChange={(e) => {
                setFormDataD({ ...formDataD, number: e.target.value });
              }}
              name="bikeNumber"
            />
          </div>
          <div className={style["inp-container"]}>
            <label htmlFor="manufacturingYear">
              Manufacturing Year<span>*</span> -- {formDataD.mfgYear}
            </label>
            <DatePicker
              // defaultValue={new Date().toISOString().split("T")[0]}
              picker={"year"}
              onChange={(date, dateString) => {
                setFormDataD({ ...formDataD, mfgYear: dateString as string });
              }}
            />
          </div>
        </div>
        <div className={style["wrap-inp"]}>
          <div className={style["inp-container"]}>
            <label htmlFor="speedLimit">
              Speed Limit<span>*</span>
            </label>
            <Input
              value={formDataD.speedLimit}
              onChange={(e) => {
                setFormDataD({ ...formDataD, speedLimit: e.target.value });
              }}
              name="speedLimit"
            />
          </div>
          <div className={style["inp-container"]}>
            <label htmlFor="timePenalty">
              Time Penalty<span>*</span>
            </label>
            <InputNumber
              value={formDataD.timePenalty}
              onChange={(value) => {
                setFormDataD({ ...formDataD, timePenalty: Number(value) });
              }}
              name="timePenalty"
            />
          </div>
        </div>
        <div className={style["wrap-inp"]}>
          <div className={style["inp-container"]}>
            <label htmlFor="distancePenalty">
              Distance Penalty<span>*</span>
            </label>
            <InputNumber
              value={formDataD.distancePenalty}
              onChange={(value) => {
                setFormDataD({ ...formDataD, distancePenalty: Number(value) });
              }}
              name="distancePenalty"
            />
          </div>
          <div className={style["inp-container"]}>
            <label htmlFor="securityAmountDeposit">
              Security Amount Deposit<span>*</span>
            </label>
            <InputNumber
              value={formDataD.securityDepositAmount}
              onChange={(value) => {
                setFormDataD({
                  ...formDataD,
                  securityDepositAmount: Number(value),
                });
              }}
              name="securityAmountDeposit"
            />
          </div>
        </div>
        <h3 style={{ margin: "0px" }}>
          Rental Calculations<span>*</span>
        </h3>
        {Object.keys(formDataD?.rentCalculations).map((key, index) => {
          return (
            <div key={index}>
              <div className={style["day-inp-container"]}>
                <div className={style["wrap-disable"]}>
                  <div className={style["title"]}> {key}</div>
                  <Switch
                    //@ts-ignore
                    value={!formDataD.rentCalculations[key]?.disabled}
                    onChange={(checked) => {
                      //@ts-ignore
                      const duplicate = formDataD.rentCalculations[key];
                      duplicate.disabled = !checked;
                      setFormDataD({
                        ...formDataD,
                        rentCalculations: {
                          ...formDataD.rentCalculations,
                          [key]: duplicate,
                        },
                      });
                    }}
                  />
                </div>
                <div className={style["wrap-price"]}>
                  <div className={style["price-conatiner"]}>
                    <div className={style["title"]}> Price</div>
                    <InputNumber
                      //@ts-ignore
                      value={formDataD.rentCalculations[key]?.price}
                      onChange={(value) => {
                        //@ts-ignore
                        const duplicate = formDataD.rentCalculations[key];
                        duplicate.price = Number(value);
                        setFormDataD({
                          ...formDataD,
                          rentCalculations: {
                            ...formDataD.rentCalculations,
                            [key]: duplicate,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={style["price-conatiner"]}>
                    <div className={style["limit-title"]}> Km Limit</div>
                    <InputNumber
                      //@ts-ignore
                      value={formDataD.rentCalculations[key]?.kmLimit}
                      onChange={(value) => {
                        //@ts-ignore
                        const duplicate = formDataD.rentCalculations[key];
                        duplicate.kmLimit = Number(value);
                        setFormDataD({
                          ...formDataD,
                          rentCalculations: {
                            ...formDataD.rentCalculations,
                            [key]: duplicate,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                {/* @ts-ignore */}
                {formDataD.rentCalculations?.[key].weekendPrice && (
                  <>
                    <div className={style["weekend-disable-price"]}>
                      <div className={style["wrap-disable"]}>
                        <div className={style["title"]}> Weekend Price</div>
                        <Switch
                          value={
                            //@ts-ignore
                            !formDataD.rentCalculations[key]?.weekendPrice
                              ?.disabled
                          }
                          onChange={(checked) => {
                            //@ts-ignore

                            const duplicate = formDataD.rentCalculations[key];
                            duplicate.weekendPrice.disabled = !checked;
                            setFormDataD({
                              ...formDataD,
                              rentCalculations: {
                                ...formDataD.rentCalculations,
                                [key]: duplicate,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className={style["wrap-price"]}>
                        <div className={style["price-conatiner"]}>
                          <div className={style["title"]}>Price</div>
                          <InputNumber
                            value={
                              //@ts-ignore
                              formDataD.rentCalculations[key]?.weekendPrice
                                ?.price
                            }
                            onChange={(value) => {
                              //@ts-ignore
                              const duplicate = formDataD.rentCalculations[key];
                              duplicate.weekendPrice.price = Number(value);
                              setFormDataD({
                                ...formDataD,
                                rentCalculations: {
                                  ...formDataD.rentCalculations,
                                  [key]: duplicate,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className={style["price-conatiner"]}>
                          <div className={style["limit-title"]}> Km Limit</div>
                          <InputNumber
                            value={
                              //@ts-ignore
                              formDataD.rentCalculations[key]?.weekendPrice
                                ?.kmLimit
                            }
                            onChange={(value) => {
                              //@ts-ignore
                              const duplicate = formDataD.rentCalculations[key];
                              duplicate.weekendPrice.kmLimit = Number(value);
                              setFormDataD({
                                ...formDataD,
                                rentCalculations: {
                                  ...formDataD.rentCalculations,
                                  [key]: duplicate,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div className={btnStyle["wrap-submit-cancel"]}>
          <CommonButton color="green" onClick={() => onSave()}>
            Save
          </CommonButton>
          <CommonButton color="secondary" onClick={() => setMode("home")}>
            Cancel
          </CommonButton>
        </div>
      </div>
    </div>
  );
}

function PartnerBikes() {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState([] as Data[]);
  const [selectedData, setSelectedData] = useState({} as Data);
  const [originalData, setOriginalData] = useState<any[]>([]);

  const onEdit = (record: Data) => {
    setSelectedData(record);
    setMode("edit");
  };

  const onCreate = () => {
    setSelectedData(defaultData);
    setMode("create");
  };

  const onDelete = async (record: Data) => {
    const Bike = await API.admin.DeleteBike(record._id as string);

    if (!API.validators.checkSuccessCode(Bike))
      message.error("Technical error");

    message.success("Deleted Successfully");
    getBikesDetails();
  };

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  const getBikesDetails = async () => {
    const stationId = storage.getSelectedStation().id;
    if (!stationId) return;
    const getData = await API.partner.GetBikes({ stationId: stationId });

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setData(getData.data?.data);
    setOriginalData(getData.data?.data);
  };

  useEffect(() => {
    getBikesDetails();
  }, [mode]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="name"
              placeholder="Search By Bike Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Add Bike
            </CommonButton>
          </div>

          <AntTable columns={columns} data={data} />
        </div>
      )}
      {mode === "create" && (
        <CreateEditBike mode="create" setMode={setMode} data={selectedData} />
      )}
      {mode === "edit" && (
        <CreateEditBike mode="edit" setMode={setMode} data={selectedData} />
      )}
    </div>
  );
}

export default PartnerBikes;
