import React, { useState, useRef, useEffect } from "react";
import { formatNumber } from "./formatNumber";
import styles from "./CustomeDropDownForNumber.module.scss"; // Import CSS module

const CustomeDropDownForNumber = ({
  option,
  selectedOption,
  onOptionChange,
}: {
  option: any;
  selectedOption: any;
  onOptionChange: any;
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleItemClick = (_id: string) => {
    setOpenMenu(false);
    onOptionChange(_id);
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div
        className={styles.dropdownButton}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <p>{selectedOption ? formatNumber(selectedOption) : "Select "}</p>
        {/* <Image src="/assets/icons/dropdown.svg" alt="dropDown" className={styles.icon} /> */}
      </div>
      {openMenu && (
        <div className={styles.dropdownMenu}>
          <ul>
            {option.map((item: any, index: number) => (
              <li
                key={index}
                className={styles.dropdownItem}
                onClick={() => handleItemClick(item)}
              >
                {formatNumber(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomeDropDownForNumber;
