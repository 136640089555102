import { message } from "antd";

export const antdMessageError = (content: string) => {
  return message.error({
    content: content,
    style: {
      marginTop: "130px",
    },
  });
};

export const antdMessageSuccess = (content: string) => {
  return message.success({
    content: content,
    style: {
      marginTop: "130px",
    },
  });
};
