import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import API from "../api";
import style from "../style/twoColForm.module.scss";
import btnStyle from "../style/btn.module.scss";
import CommonTable from "../components/CommonTable";
import { UploadOutlined } from "@ant-design/icons";
import CommonButton from "../components/CommonButton";
import { AxiosResponse } from "axios";
import {
  Button,
  Input,
  Select,
  Space,
  TableProps,
  Upload,
  message,
} from "antd";
import SearchFeature from "../partner/SearchFeture";

interface Data {
  _id: string;
  name: string;
  brandName: string;
  imgUrl: string;
  img?: any;
  transmissionType: string;
  engineType: string;
  kerbWeight: string;
  displacement: string;
  mileage: string;
  tankCapacity: string;
  ridingRange: string;
  chargingTime: string;
  motorPower: string;
}

function CreateEditBike({
  mode,
  setMode,
  data,
  id,
}: {
  mode: "edit" | "create";
  setMode: Dispatch<SetStateAction<"edit" | "create" | "home">>;
  data: Data;
  id?: string;
}) {
  const [formDataD, setFormDataD] = useState(data);
  const onSave = async () => {
    if (!formDataD.name) return message.error("Name is required");
    if (!formDataD.brandName) return message.error("Brand Name is required");
    if (mode === "create" && !formDataD.img)
      return message.error("Image is required");

    const formData = new FormData();

    formData.append("name", formDataD.name);
    formDataD.img && formData.append("image", formDataD.img);
    formData.append("brandName", formDataD.brandName || "");
    formData.append("transmissionType", formDataD.transmissionType || "");
    formData.append("engineType", formDataD.engineType || "");
    formData.append("kerbWeight", formDataD.kerbWeight || "");
    formData.append("displacement", formDataD.displacement || "");
    formData.append("mileage", formDataD.mileage || "");
    formData.append("ridingRange", formDataD.ridingRange || "");
    formData.append("chargingTime", formDataD.chargingTime || "");
    formData.append("motorPower", formDataD.motorPower || "");
    // formData.append('disabled', formDataD.disabled || '')

    let createBike;

    if (mode === "create") createBike = await API.admin.CreateBike(formData);
    if (mode === "edit")
      createBike = await API.admin.EditBike(formData, formDataD._id);

    if (
      API.validators.checkSuccessCode(createBike as AxiosResponse<any, any>)
    ) {
      console.log(createBike);
      message.success("Details uploaded successfully");
      setMode("home");
    } else {
      message.error("Technical Error Occured");
    }
  };

  return (
    <div className={style["container"]}>
      <h1>Add Bike Details</h1>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="name">
            Bike Name<span>*</span>
          </label>
          <Input
            value={formDataD.name}
            onChange={(e) => {
              setFormDataD({ ...formDataD, name: e.target.value });
            }}
            name="name"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="brandName">
            Brand Name<span>*</span>
          </label>
          <Input
            value={formDataD.brandName}
            onChange={(e) => {
              setFormDataD({ ...formDataD, brandName: e.target.value });
            }}
            name="brandName"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="transmissionType">
            Transmission Type<span>*</span>
          </label>
          <Select
            defaultValue="gear"
            onChange={(value) => {
              setFormDataD({ ...formDataD, transmissionType: value });
            }}
            options={[
              { value: "gear", label: "Gear" },
              { value: "gearless", label: "No Gear" },
            ]}
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="engineType">
            Engine Type<span>*</span>
          </label>
          <Select
            defaultValue="motor"
            onChange={(value) => {
              setFormDataD({ ...formDataD, engineType: value });
            }}
            options={[
              { value: "ev", label: "EV" },
              { value: "motor", label: "Motor" },
            ]}
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="kerbWeight">
            Kerb weight<span>*</span>
          </label>
          <Input
            value={formDataD.kerbWeight}
            onChange={(e) => {
              setFormDataD({ ...formDataD, kerbWeight: e.target.value });
            }}
            name="kerbWeight"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="displacement">
            Displacement<span>*</span>
          </label>

          <Input
            value={formDataD.displacement}
            onChange={(e) => {
              setFormDataD({ ...formDataD, displacement: e.target.value });
            }}
            name="displacement"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="mileage">
            Mileage<span>*</span>
          </label>
          <Input
            value={formDataD.mileage}
            onChange={(e) => {
              setFormDataD({ ...formDataD, mileage: e.target.value });
            }}
            name="mileage"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="tankCapacity">
            Tank Capacity<span>*</span>
          </label>

          <Input
            value={formDataD.tankCapacity}
            onChange={(e) => {
              setFormDataD({ ...formDataD, tankCapacity: e.target.value });
            }}
            name="tankCapacity"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="chargingTime">
            Charging Time<span>*</span>
          </label>
          <Input
            value={formDataD.chargingTime}
            onChange={(e) => {
              setFormDataD({ ...formDataD, chargingTime: e.target.value });
            }}
            name="chargingTime"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="ridingRange">
            Riding Range<span>*</span>
          </label>

          <Input
            value={formDataD.ridingRange}
            onChange={(e) => {
              setFormDataD({ ...formDataD, ridingRange: e.target.value });
            }}
            name="ridingRange"
          />
        </div>
      </div>
      <div className={style["wrap-inp"]}>
        <div className={style["inp-container"]}>
          <label htmlFor="motorPower">
            Motor Power<span>*</span>
          </label>
          <Input
            value={formDataD.motorPower}
            onChange={(e) => {
              setFormDataD({ ...formDataD, motorPower: e.target.value });
            }}
            name="motorPower"
          />
        </div>
        <div className={style["inp-container"]}>
          <label htmlFor="upload">
            Upload<span>*</span>
          </label>

          <Upload
            beforeUpload={(file) => {
              setFormDataD({ ...formDataD, img: file });

              return false;
            }}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload image</Button>
          </Upload>
        </div>
      </div>
      <div className={btnStyle["wrap-submit-cancel"]}>
        <CommonButton color="green" onClick={() => onSave()}>
          Save
        </CommonButton>
        <CommonButton color="secondary" onClick={() => setMode("home")}>
          Cancel
        </CommonButton>
      </div>
    </div>
  );
}

function Bike() {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState([] as Data[]);
  const [selectedData, setSelectedData] = useState({} as Data);
  const [originalData, setOriginalData] = useState<any[]>([]);

  const onEdit = (record: Data) => {
    setSelectedData(record);
    setMode("edit");
  };

  const onCreate = () => {
    setSelectedData({
      _id: "",
      name: "",
      imgUrl: "",
      brandName: "",
      img: "",
      transmissionType: "gear",
      engineType: "motor",
      kerbWeight: "",
      displacement: "",
      mileage: "",
      tankCapacity: "",
      ridingRange: "",
      chargingTime: "",
      motorPower: "",
    });
    setMode("create");
  };

  const onDelete = async (record: Data) => {
    const bike = await API.admin.DeleteBike(record._id);

    if (!API.validators.checkSuccessCode(bike))
      message.error("Technical error");

    message.success("Deleted Successfully");
    getBikes();
  };

  const columns: TableProps<Data>["columns"] = [
    {
      title: "Image",
      dataIndex: "imgUrl",
      key: "imgUrl",
      render: (text: any) => (
        <img style={{ width: "40px", height: "40px" }} src={text} alt="img" />
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  const getBikes = async () => {
    const getData = await API.admin.GetBikes();

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setData(getData.data?.data);
    setOriginalData(getData.data?.data);
  };

  useEffect(() => {
    getBikes();
  }, [mode]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="name"
              placeholder="Search By Bike Name"
            />

            <CommonButton color="primary" onClick={() => onCreate()}>
              Add Bike
            </CommonButton>
          </div>

          <CommonTable columns={columns} data={data} />
        </div>
      )}
      {mode === "create" && (
        <CreateEditBike mode="create" setMode={setMode} data={selectedData} />
      )}
      {mode === "edit" && (
        <CreateEditBike mode="edit" setMode={setMode} data={selectedData} />
      )}
    </div>
  );
}

export default Bike;
