import styles from "./switchBtn.module.scss";
const SwitchBtn = ({
  checked,
  onChange,
  classNameValue,
  title,
}: {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classNameValue?: string;
  title: string;
}) => {
  return (
    <div className={classNameValue}>
      <div className={styles["container"]}>
        <p className={styles["title"]}>{title}</p>
        <label className={styles["label"]}>
          <input
            type="checkbox"
            className="sr-only peer"
            checked={checked}
            onChange={onChange}
          />
          {/* <div className="relative w-9 h-5 bg-gray-200 rounded-full peer  dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
        </label>
        {/* <input className="border rounded ml-4" /> */}
      </div>
    </div>
  );
};

export default SwitchBtn;
