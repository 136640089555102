import { message } from "antd";
import React, { useEffect, useState } from "react";
import API from "../api";
import CommonButton from "../components/CommonButton";
import { storage } from "../utils/storage";
import style from "../style/oneColForm.module.scss";
import TableComponent, { DataConst } from "./blockConsts";
import SearchFeature from "./SearchFeture";
import PartnerBlocksEditAndCreateForm from "./BlocksEditAndCreateForm";

const PartnerBikeBlocks = () => {
  const [mode, setMode] = useState("home" as "edit" | "create" | "home");
  const [data, setData] = useState([] as DataConst[]);
  const [selectedData, setSelectedData] = useState({} as DataConst);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const onCreate = () => {
    setSelectedData({
      from: "",
      to: "",
      bikeId: { bikeVariant: { imgUrl: "" }, name: "", _id: "" },
      stationId: storage.getSelectedStation().id,
      _id: "",
    });
    setMode("create");
  };

  const findBlockBikeList = async () => {
    const getData = await API.partner.GetBlockBikeList({
      stationId: storage.getSelectedStation().id,
    });

    if (!API.validators.checkSuccessCode(getData)) {
      return message.error("Technical error");
    }

    setData(getData.data?.data);
    setOriginalData(getData.data?.data);
  };

  useEffect(() => {
    findBlockBikeList();
  }, [mode, refresh]);

  return (
    <div>
      {mode === "home" && (
        <div>
          <div className={style["warp-search-inp"]}>
            <SearchFeature
              originalData={originalData}
              setData={setData}
              keyData="bikeId.bikeVariant.name"
              placeholder="Search By Bike Name"
            />
            <CommonButton color="primary" onClick={() => onCreate()}>
              Add Bike Block
            </CommonButton>
          </div>
          <TableComponent
            data={data}
            setSelectedData={setSelectedData}
            setMode={setMode}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </div>
      )}
      {mode === "create" && (
        <PartnerBlocksEditAndCreateForm
          mode="create"
          setMode={setMode}
          data={selectedData}
        />
      )}
      {mode === "edit" && (
        <PartnerBlocksEditAndCreateForm
          mode="edit"
          setMode={setMode}
          data={selectedData}
        />
      )}
    </div>
  );
};

export default PartnerBikeBlocks;
